import { HTTP } from '@/axios.config'
import localizeText from '@/locales/localize';
import {notify} from "@kyvg/vue3-notification";

const fetchStudentAblums = async (studentId) => {
  const params = {
    appends: ['image'],
    relations: ['title', 'pictures.title', 'name', 'age', 'vocation', 'mentor', 'world', 'achievement']
  }
  return await HTTP.get(`/students/${studentId}/albums`, {params}).then(res => res.data.data)
}

const fetchStudentAblumById = async ({studentId, albumId, params}) => {
  return await HTTP.get(`/students/${studentId}/albums/${albumId}`, {params}).then(res => res.data.data)
}

const fetchStudentAblumPictures = async ({studentId, albumId, params}) => {
  return await HTTP.get(`/students/${studentId}/albums/${albumId}/pictures`, {params}).then(res => res.data.data)
}


const fetchStudentAblumPuzzles = async ({studentId, albumId, params}) => {
  return await HTTP.get(`/students/${studentId}/albums/${albumId}/puzzles`, {params}).then(res => res.data.data)
}

const fetchAblumPuzzles = async ({albumId, params}) => {
  return await HTTP.get(`/albums/${albumId}/puzzles`, {params}).then(res => res.data.data)
}

const fetchStudentPuzzles = async (studentId) => {
  const params = {
    fields: ['id', 'picture_id'],
    appends: ['image']
  }
  return await HTTP.get(`/students/${studentId}/puzzles`, {params}).then(res => res.data.data)
}

const fetchAblumPictures = async ({albumId, params}) => {
  return await HTTP.get(`/albums/${albumId}/pictures`, {params}).then(res => res.data.data)
}

const fetchStudentCrystals = async (id, params) => {
  return await HTTP.get(`/students/${id}/crystals`, { params }).then(res => res.data.data)
}

const buyCrystal = async ({studentId, subjectId, crystalId}) => {
  const params = { subject_id: subjectId }
  const res = await HTTP.post(`/students/${studentId}/crystals/${crystalId}`, params).then(res => res.data)

  if (res.status) {
    notify({
      title: localizeText('AlbumsApiNotify1'),
      type: 'success'
    })
  } else {
    notify({
      title: localizeText('AlbumsApiNotify2'),
      type: 'error'
    })
  }
  return res.status
}

const buyPuzzles = async (data) => {
  const {studentId, subjectId, ids, price, isRandom = false} = data
  const res = await HTTP.post(`/students/${studentId}/puzzles`, {
    puzzle_ids: ids,
    is_random: isRandom,
    subject_id: subjectId,
    price
  }).then(res => res.data)

  if (res.status) {
    notify({
      title: localizeText('AlbumsApiNotify3'),
      type: 'success'
    })
  } else {
    notify({
      title: localizeText('AlbumsApiNotify4'),
      type: 'error'
    })
  }
  return res.status
}

const joinStudentToAlbums = async ({studentId, subjectId}) => {
  const params = { subject_id: subjectId }

  return await HTTP.post(`/students/${studentId}/albums`, params).then(res => res.data)
}

const saveAlbumPicture = async ({studentId, pictureId, params}) => {
  const res = await HTTP.patch(`/students/${studentId}/pictures/${pictureId}`, params).then(res => res.data)

  if (res.status) {
    notify({
      title: localizeText('AlbumsApiNotify7'),
      type: 'success'
    });
  } else {
    notify({
      title: localizeText('AlbumsApiNotify8'),
      type: 'error'
    });
  }
  return res.status
}

const useCrystal = async({studentId, crystalId, params}) => {
  const res = await HTTP.patch(`/students/${studentId}/crystals/${crystalId}`, params).then(res => res.data)

  if (res.status) {
    notify({
      title: localizeText('AlbumsApiNotify9'),
      type: 'success'
    });
  } else {
    notify({
      title: localizeText('AlbumsApiNotify10'),
      type: 'error'
    });
  }
  return res.status
}

const updatePuzzles = async({studentId, params}) => {
  const res = await HTTP.patch(`/students/${studentId}/puzzles`, params).then(res => res.data)

  if (res.status) {
    notify({
      title: localizeText('AlbumsApiNotify11'),
      type: 'success'
    });
  } else {
    notify({
      title: localizeText('AlbumsApiNotify12'),
      type: 'error'
    });
  }
  return res.status
}

export {
  // init
  joinStudentToAlbums,

  // student albums
  fetchStudentAblums,
  fetchStudentAblumById,
  fetchStudentAblumPictures,
  fetchStudentAblumPuzzles,
  fetchStudentPuzzles,
  fetchAblumPuzzles,
  fetchAblumPictures,
  saveAlbumPicture,
  updatePuzzles,
  buyPuzzles,

  // crystals
  fetchStudentCrystals,
  buyCrystal,
  useCrystal,
}
