import { HTTP } from '@/axios.config'
import { notify } from "@kyvg/vue3-notification";
import store from '@/store'

const DEARIES_VERSION = 1

const fetchTrainersRating = async() => {
  return await HTTP.get(`/ratings/trainers`).then(res => res.data)
}

const fetchLikesRating = async(subjectId) => {
  return await HTTP.get(`/ratings/likes`, {params: { subjectId}}).then(res => res.data)
}

const fetchOnlineRating = async() => {
  return await HTTP.get(`/ratings/online`).then(res => res.data)
}

const fetchDiaries = async({studentId, subjectId}) => {
  return await HTTP.get(`/students/${studentId}/diaries`, {params: { subject_id: subjectId }}).then(res => res.data.data)
}

const fetchDiaryById = async(id) => {
  return await HTTP.get(`/diaries/${id}`).then(res => res.data.data)
}

const createDiary = async(data) => {
  data.version = DEARIES_VERSION
  const res = await HTTP.post(`/diaries`, data).then(res => res.data)

  if (!res.status) {
    notify({
      title: res.message ? res.message[store.state.locale] : 'error',
      type: 'error'
    })
  }

  return res.status
}

const updateDiary = async(id, data) => {
  data.version = DEARIES_VERSION
  const res = await HTTP.put(`/diaries/${id}`, data).then(res => res.data)

  if (!res.status) {
    notify({
      title: res.message ? res.message[store.state.locale] : 'error',
      type: 'error'
    })
  }

  return res.status
}

const deleteDiary = async(id) => {
  return await HTTP.delete(`/diaries/${id}`).then(res => res.data.status)
}

export {
  fetchTrainersRating,
  fetchLikesRating,
  fetchOnlineRating,
  fetchDiaries,
  fetchDiaryById,
  createDiary,
  updateDiary,
  deleteDiary
}
