import { fetchStudentAblumPuzzles, fetchAblumPictures } from '@/api/albums'
import { fetchMarketLots, deleteMarketLot, createMarketLot, buyMarketLot } from '@/api/market'

export default {
  namespaced: true,
  state: {
    lots: [],
    pictures: [],
    puzzles: [],
    crystals: []
  },
  getters: {
    filteredPuzzles: state => picId => state.puzzles.filter(p => p.picture_id === picId),
    avaiableLot: state => id => state.lots.some(l => l.id === id)
  },
  actions: {
    getLots: async ({commit}) => {
      const lots = await fetchMarketLots({
        appends: ['items.studentObject.object.image'],
        relations: ['seller:id,name', 'items.studentObject.object.title'],
      })

      commit('SET_LOTS', lots)
    },
    buyLot: async ({commit, rootState}, { id, params }) => {
      params.subject_id = rootState.student.subject.id

      const status = await buyMarketLot({id, params})
      if (status) {
        commit('REMOVE_LOT', id)
      }
    },
    createLot: async ({dispatch, rootState}, data) => {
      data.subject_id = rootState.student.subject.id

      const status = await createMarketLot(data)

      if (status) {
        if (data.items.some(i => i.item_type === 'crystal_student')) {
          dispatch('crystals/getStudentCrystals', null, { root: true })
        }
      }
    },
    deleteLot: async ({rootState, commit}, lotId) => {
      const studentId = rootState.user.id
      const status = await deleteMarketLot({ studentId, lotId })
      if (status) {
        commit('REMOVE_LOT', lotId)
      }
    },
    getUserItems: async ({commit, rootState}, albumId) => {
      const studentId = rootState.user.id

      const pictures = await fetchAblumPictures({
        albumId: albumId,
        params: {
          relations: ['title', 'puzzles'],
          appends: ['image', 'puzzles.image']
        }
      })

      const puzzles = await fetchStudentAblumPuzzles({
        studentId: studentId,
        albumId: albumId,
        params: {
          appends: ['image']
        }
      })

      commit('SET_USER_ITEMS', {  pictures, puzzles })
    }
  },
  mutations: {
    SET_LOTS: (state, lots) => {
      state.lots = lots || []
    },
    SET_USER_ITEMS: (state, { pictures, puzzles }) => {
      state.pictures = pictures || []
      state.puzzles = puzzles || []
    },
    REMOVE_ITEM: (state, { el, type }) => {
      const targetArr = type === 'puzzle' ? state.puzzles : state.crystals
      const item = targetArr.find(i => i.id === el.id)

      if (item) {
        const index = targetArr.indexOf(item)
        targetArr.splice(index, 1)
      }
    },
    ADD_LOT: (state, lot) => state.lots.unshift(lot),
    REMOVE_LOT: (state, lotId) => {
      const item = state.lots.find(i => i.id === lotId)

      if (item) {
        const index = state.lots.indexOf(item)
        state.lots.splice(index, 1)
      }
    }
  }
}
