import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import { notify } from '@kyvg/vue3-notification'
import store from '@/store'
import localizeText from '@/locales/localize'

const fetchTeacherProjects = async (teacherId) => {
  return await HTTP.get(`/teachers/${teacherId}/it-projects`).then((res) => res.data)
}

const fetchProjects = async (data) => {
  return await HTTP_NO_SPINNER.get(`/it-projects`, { params: data }).then((res) => res.data)
}

const fetchProjectItem = async (id) => {
  return await HTTP_NO_SPINNER.get(`/it-projects/${id}`).then((res) => res.data)
}

const fetchItScores = async (id) => {
  return await HTTP_NO_SPINNER.get(`/students/${id}/it-scores/count`).then((res) => res.data.result)
}

const createProject = async(data) => {
  const res = await HTTP.post(`/it-projects`, data).then(res => res).catch(res => res.response)

  if (res.status === 400) {
    const message = res.data.message
    const locale = store.state.locale

    notify({
      title: message ? message[locale] : 'Error',
      type: 'error',
    })
    return false
  }

  return true
}

const approveProject = async(project) => {
  project.append('_method', 'patch')
  const res = await HTTP.post(`/it-projects/${project.get('id')}`, project).then(res => res).catch(res => res.response)

  if (res.status === 200) {
    notify({
      title: localizeText('ApiItProjectsApproveSuccess'),
      type: 'success',
    })
    return true
  }

  return false
}

const cancelProject = async(id) => {
  const res = await HTTP.delete(`/it-projects/${id}`).then(res => res).catch(res => res.response)

  if (res.status === 200) {
    notify({
      title: localizeText('ApiItProjectsCancelSuccess'),
      type: 'success',
    })
    return true
  }

  return false
}

const likeProject = async(projectId) => {
  const res = await HTTP_NO_SPINNER.post(`/it-project-likes`, { projectId }).then(res => res).catch(res => res.response)

  if (res.status === 400) {
    notify({
      title: 'Like error',
      type: 'error',
    })
    return false
  }

  return true
}

const dislikeProject = async(projectId) => {
  const res = await HTTP_NO_SPINNER.delete(`/it-project-likes`, {params: { projectId }}).then(res => res).catch(res => res.response)

  if (res.status === 400) {
    notify({
      title: 'Like error',
      type: 'error',
    })
    return false
  }

  return true
}

export {
  createProject,
  fetchTeacherProjects,
  fetchProjects,
  approveProject,
  cancelProject,
  likeProject,
  dislikeProject,
  fetchProjectItem,
  fetchItScores
}
