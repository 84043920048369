import randomInteger from "@/helpers/randomInteger"
export default {
  namespaced: true,
  state: {
    animations: [
      {
        data: require('@/animations/animation-1.json'),
        negative: false
      },
      {
        data: require('@/animations/animation-2.json'),
        negative: false
      },
      {
        data: require('@/animations/animation-3.json'),
        negative: false
      },
      {
        data: require('@/animations/animation-4.json'),
        negative: true
      },
      {
        data: require('@/animations/animation-5.json'),
        negative: true
      },
      {
        data: require('@/animations/animation-6.json'),
        negative: true
      }
    ]
  },
  getters: {
    getPositive: (state) => {
      const arr = state.animations.filter(a => !a.negative)
      const index = randomInteger(0, arr.length - 1)

      return arr[index].data
    },
    getNegative: (state) => {
      const arr = state.animations.filter(a => a.negative)
      const index = randomInteger(0, arr.length - 1)

      return arr[index].data
    }
  },
  mutations: {

  },
  actions: {

  }
}