<template>
  <div class="viewer" v-if="isShowed">
    <img :src="images.state" alt="">
    <AppButtonIcon size="2rem" class="viewer__close viewer__btn" icon="xmark" color="#fff" @click="close" />
    <div class="viewer__controls">
      <AppButtonIcon size="2rem" class="viewer__btn" icon="arrow-left" color="#fff" @click="images.prev()" />
      <AppButtonIcon size="2rem" class="viewer__btn" icon="arrow-right" color="#fff" @click="images.next()" />
    </div>
  </div>
</template>

<script setup>
import { useCycleList } from '@vueuse/core';
import { ref } from 'vue';
import AppButtonIcon from '@/app/base/components/AppButtonIcon.vue';

const isShowed = ref(false)
const images = ref(null)

const open = ({ array, slider }) => {
  isShowed.value = true

  if (array) {
    images.value = useCycleList(array)
  } else {
    images.value = slider
  }
}

const close = () => {
  isShowed.value = false
  images.value = null
}

defineExpose({ open })
</script>

<style lang="scss" scoped>
.viewer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: #000;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.viewer__close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.viewer__btn {
  background-color: rgba(0, 0, 0, 0.3);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.viewer__controls {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

}
</style>
