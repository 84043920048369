import { getMessages, readMessage, deleteMessages } from '@/api/messages'
import _ from 'lodash'
import moment from 'moment'

const sound = new Audio('/storage/sounds/message.mp3')
export default {
  namespaced: true,
  state: {
    messages: []
  },
  getters: {
    unreadMessages: (state) => state.messages.filter(m => !m.is_read).length,
    unreadInWeek: (state) => {
      const messages = state.messages.filter(m => !m.is_read)

      if (!messages.length) return false

      const oldest = messages[messages.length - 1]
      const currentDate = moment()
      const oldestDate = moment(oldest.created_at, 'DD.MM.YYYY hh.mm.ss')

      return oldestDate.add(7, 'days').isBefore(currentDate)
    }
  },
  mutations: {
    SET_MESSAGES: (state, messages) => {
      state.messages = _.orderBy(messages, 'id', 'desc')
      if (state.messages.find(m => !m.is_read)) sound.play()
    },
    READ_MESSAGE: (state, id) => {
      let message = state.messages.find(m => m.id === id)
      message.is_read = true
    },
    ADD_MESSAGE: (state, message) => {
      state.messages.unshift(message)
    },
    CLEAR_MESSAGES: (state) => {
      state.messages = []
    }
  },
  actions: {
    fetchMessages: async ({ commit, rootState }) => {
      const userId = rootState.user.id
      const subjectId = rootState.student.subject.id
      const messages = await getMessages({userId, subjectId})
      commit('SET_MESSAGES', messages)
    },
    readMessageItem: async (ctx, messageId) => {
      ctx.commit('READ_MESSAGE', messageId)
      await readMessage(messageId)
    },
    clearMessages: async ({commit, rootState}) => {
      const userId = rootState.user.id
      const subjectId = rootState.student.subject.id

      await deleteMessages({userId, subjectId})
      commit('CLEAR_MESSAGES')
    }
  }
}
