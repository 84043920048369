<template>
  <div class="chats">
    <div class="chats__header">
      <h2 class="chats__title">
        {{ $localize('AppChatTitle') }}
      </h2>
      <AppButtonIcon @click="addNewChat" icon="plus" size="1.5rem" color="#000" />
    </div>
    <div class="chats__body">
      <app-loader class="loader" :loading="isLoading" color="#000"></app-loader>

      <ul class="chats__list" v-if="!isLoading">
        <li
          @click="openChat(c.target.id)"
          class="chats__item"
          :class="{unread: c.from_id !== user.id && !c.is_read}"
          v-for="c in chats"
          :key="c.id"
        >
          <h3>{{ c.target.name }}</h3>
          <p v-if="c.text" v-cut="50">{{ c.author?.name }}: {{ c.text.length > 50 ? c.text.slice(0, 50) + '...' : c.text }}</p>
          <p v-else>{{ $localize('AppChatNoText') }}</p>
          <span>{{ c.created_at }}</span>
        </li>
      </ul>
    </div>

    <div class="chats__sidebar" :class="{active: sidebarComp}">
      <component
        :is="sidebarComp"
        :chatData="chatData"
        @markAsRead="markAsRead"
        @close="onClose"
        @select="openChat"
      ></component>
    </div>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, markRaw, ref } from 'vue'
import { useStore } from 'vuex'
import { fetchUserChats } from "@/api/users";
import useSounds from '@/app/base/composables/useSounds';
import AppButtonIcon from '@/app/base/components/AppButtonIcon.vue';

const store = useStore()
const user = computed(() => store.state.user)
const isLoading = ref(false)

const chatData = ref({
  fromId: null,
  toId: null
})

const sidebarComp = ref(null)
const ChatItemComponent = markRaw(defineAsyncComponent(() => import('@/app/student/components/StudentJenphoneChatItem.vue')))
const ChatNewComponent = markRaw(defineAsyncComponent(() => import('@/app/student/components/StudentJenphoneChatNew.vue')))

const chats = ref([])
const hasUnreadMessages = computed(() => chats.value.some(c => !c.is_read && c.author?.id !== user.value.id))
defineExpose({ hasUnreadMessages })

const getChats = async() => {
  isLoading.value = true
  chats.value = await fetchUserChats(user.value.id)
  isLoading.value = false
}

const openChat = (targetId) => {
  chatData.value.fromId = user.value.id
  chatData.value.toId = targetId

  const chat = chats.value.find(c => c.to_id === targetId)
  if (chat) chat.is_read = true

  sidebarComp.value = ChatItemComponent
}

const addNewChat = () => {
  sidebarComp.value = ChatNewComponent
}

const markAsRead = () => {
  chats.value.forEach(c => c.is_read = true)
}

const onClose = () => {
  chatData.value.fromId = null
  chatData.value.toId = null

  sidebarComp.value = null

  getChats()
}

const initChat = async () => {
  const { playSound } = useSounds()
    getChats()

    window.Echo
      .private(`users.${user.value.id}.chats`)
      .listen('.newMessage', e => {
        const message = e
        message.target = message.from_id === user.value.id ? message.recipient : message.author
        const chat = chats.value.find(c => c.target.id === message.target.id)

        if (chat) {
          chat.text = message.text
          chat.author = message.author
          chat.is_read = false
          chat.created_at = message.created_at
          playSound('message')
        } else {
          getChats()
        }
      })
}

initChat()
</script>

<style lang="scss" scoped>

.loader {
  margin: auto;
}
.chats {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  padding: 1.4rem;
  background-color: #fff;
  overflow: hidden;
  position: relative;
}

.chats__header {
  padding: 1rem 0;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chats__body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 1px;
  position: relative;
}

.chats__list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.chats__item {
  position: relative;
  padding: 0.5rem;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 0.3rem;

  & h3 {
    font-size: 1rem
  }

  & p {
    font-size: 0.9rem;
    color: #a2a2a2;
    word-break:break-all;
  }

  & span {
    font-size: 0.7rem;
    color: #a2a2a2;
  }
  &.unread::after {
    position: absolute;
    content: "";
    top: 0.7rem;
    right: 0.7rem;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: var(--a-color-2);
  }
  &:hover {
    background-color: #e2e2e2;
  }
}

.chats__sidebar {
  padding: 1.4rem;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  // transition: transform 0.3s;
  background-color: #fff;
  z-index: 100;

  &.active {
    transform: translateX(0);
  }
}

</style>
@/app/base/composables/useSounds