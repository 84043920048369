import { addItem, deleteItem } from '@/helpers/arrActions'
import { createGroup, updateGroup, deleteGroup, fetchGroups } from '@/api/groups'
import { fetchSubjects } from '@/api/subjects'
import { attachStudentToGroup, detachStudentFromGroup, fetchTeacherStudents, moveStudent } from '@/api/students'

export default {
  namespaced: true,
  state: {
    groups: [],
    students: [],
    subjects: []
  },
  getters: {
    groupById: (state) => (id) => state.groups.find(g => +g.id === +id),
    subjectById: (state) => (id) => state.subjects.find(s => +s.id === +id),
    student: state => id => state.students.find(s => +s.id === +id),
    groupSubject: state => id => state.groups.find(g => g.id === id)?.subject_id,
    subjectMainId: (state, getters) => id => {
      const subj = getters.subjectById(id)

      return subj?.main_id || null
    },
    groupStudents: state => id => {
      return state.students.filter(s => s.groups.some(g => g.id === +id))
    }
  },
  mutations: {
    UPDATE_GROUPS: (state, groups) => state.groups = groups,
    UPDATE_SUBJECTS: (state, subjects) => state.subjects = subjects,

    UPDATE_STUDENTS: (state, students) => state.students = students,
    ADD_STUDENT: (state, student) => addItem(student, state.students),
    ATTACH_STUDENT_TO_GROUP: (state, student) => {
      const st = state.students.find(s => s.id == student.id)

      if (!st) return

      addItem(student.attachingGroup, st.groups)
    },
    REMOVE_STUDENT: (state, student) => deleteItem(student, state.students),

    UPDATE_GROUP_ITEM: (state, group) => addItem(group, state.groups),
    DELETE_GROUP_ITEM: (state, group) => deleteItem(group, state.groups)
  },
  actions: {
    getGroups: async ({ commit, rootState }) => {
      const teacherId = rootState.user.id
      const groups = await fetchGroups(teacherId)

      if (groups) commit('UPDATE_GROUPS', groups)
    },
    getSubjects: async (ctx) => {
      const subjects = await fetchSubjects()

      if (subjects) {
        ctx.commit('UPDATE_SUBJECTS', subjects)
      }
    },
    getStudents: async ({ commit, rootState }) => {
      const teacherId = rootState.user.id
      const students = await fetchTeacherStudents(teacherId)

      if (students) commit('UPDATE_STUDENTS', students)
    },
    attachStudent: async ({ commit }, student) => {
      const status = await attachStudentToGroup({
        groupId: student.attachingGroup.id,
        studentId: student.id
      })

      if (status) commit('ATTACH_STUDENT_TO_GROUP', student)

      return status
    },
    detachStudent: async ({ commit }, {groupId, student}) => {
      const status = await detachStudentFromGroup({
        groupId: groupId,
        studentId: student.id
      })

      student.groups = student.groups.filter(g => g.id !== groupId)

      if (status) commit('ADD_STUDENT', student)

      return status
    },
    moveStudentToNewGroup: async ({ commit, state }, { newGroup, reqData }) => {
      const status = await moveStudent(reqData)
      const student = state.students.find(s => s.id === reqData.student_id)

      if (status && student) {
        const oldGroup = student.groups.find(g => g.id === reqData.old_group_id)
        const index = student.groups.indexOf(oldGroup)
        student.groups.splice(index, 1, newGroup)

        commit('ADD_STUDENT', student)
      }

      return status
    },
    createGroup: async (ctx, data) => {
      const group = await createGroup(data)

      if (group) {
        ctx.commit('UPDATE_GROUP_ITEM', group)
        return true
      }
      return false
    },
    updateGroup: async (ctx, group) => {
      const status = await updateGroup({
        id: group.id,
        data: group
      })

      if (status) {
        ctx.commit('UPDATE_GROUP_ITEM', group)
      }

      return status
    },
    deleteGroup: async (ctx, group) => {
      const status = await deleteGroup(group.id)
      if (status) {
        ctx.commit('DELETE_GROUP_ITEM', group)
      }
      return status
    }
  }
}
