<template>
  <button ref="btn" :class="{active: active}" type="button">
    <fa-icon class="icon" :icon="icon"></fa-icon>
    <slot></slot>
  </button>
</template>

<script setup>
import { onMounted, ref } from "vue"

const props = defineProps({
  icon: String,
  color: {
    type: String,
    default: 'white'
  },
  size: {
    type: String,
    default: '22'
  },
  activeColor: {
    type: String,
    default: '#4552e7'
  },
  active: {
    type: Boolean,
    default: false
  }
})

const btn = ref(null)

onMounted(() => {
  const size = isNaN(Number(props.size)) ? props.size : props.size + 'px'

  btn.value.style.setProperty('--color', props.color)
  btn.value.style.setProperty('--size', size)
  btn.value.style.setProperty('--activeColor', props.activeColor)
})
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';
@import '/src/assets/scss/variables/variables.scss';

button {
  --color: #fff;
  --size: 22px;
  --activeColor: #4552e7;
  // width: 2rem;
  // height: 2rem;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  color: var(--color);
  font-size: var(--size);
  border: none;
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    transform: scale(1.05);
  }
  &.active {
    color: var(--activeColor);
  }
  &:disabled {
    filter: saturate(0.3);
  }
}
</style>
