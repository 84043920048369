import { computed } from 'vue'

export default function useSubjectTheme(store) {
  const profession = computed(() => store.state.student.profession)
  const subject = computed(() => store.state.student.subject)
  const app = document.body

  const setMath = () => {
    const bg = profession.value?.background || '/img/bg-main.webp'
    app.style.setProperty('--s-bg-image', `url(${bg})`)
    app.style.setProperty('--s-header-color', '#3D4E9E')
  }

  const setIt = () => {
    const bg = '/img/bg-main-it.webp'
    app.style.setProperty('--s-bg-image', `url(${bg})`)
    app.style.setProperty('--s-header-color', '#1F42BF')
  }

  const setReader = () => {
    const bg = '/img/bg-main.webp'

    // NY theme
    // const bg = '/storage/NY-bg.jpg'
    app.style.setProperty('--s-bg-image', `url(${bg})`)
    app.style.setProperty('--s-header-color', '#098E73')
    // app.style.setProperty('--s-header-color', '#09678f')
  }

  const setMental = () => {
    const bg = '/img/bg-main.webp'

    // NY theme
    // const bg = '/storage/NY-bg.jpg'

    app.style.setProperty('--s-bg-image', `url(${bg})`)
    app.style.setProperty('--s-header-color', '#1F42BF')
  }

  const setSpeech = () => {
    const bg = '/img/bg-main-speech.webp'

    // NY theme
    // const bg = '/storage/NY-bg.jpg'

    app.style.setProperty('--s-bg-image', `url(${bg})`)
    app.style.setProperty('--s-header-color', '#6e6510')
  }

  const setTheme = () => {
    switch(subject.value.main_id) {
      case 1:
        setMath()
        break
      case 2:
        setReader()
        break
      case 3:
        setMental()
        break
      case 8:
        setIt()
        break
      case 9:
        setSpeech()
        break
      default:
        setMental()

    }
  }

  setTheme()
}
