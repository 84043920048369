import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faLanguage,
  faUsersLine,
  faSquarePlus,
  faCircleLeft,
  faCircleMinus,
  faPenToSquare,
  faRightFromBracket,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faUserMinus,
  faUserPlus,
  faShare,
  faLock,
  faLockOpen,
  faUsersGear,
  faBriefcase,
  faGamepad,
  faUsers,
  faPaste,
  faCircleRight,
  faCheck,
  faFlagCheckered,
  faEnvelope,
  faPersonChalkboard,
  faPlay,
  faStop,
  faGear,
  faXmark,
  faChild,
  faCirclePlay,
  faCircleStop,
  faCircleCheck,
  faComments,
  faPaperPlane,
  faFilePen,
  faPlus,
  faFolderPlus,
  faFolder,
  faFileArrowUp,
  faFilePdf,
  faExpand,
  faCompress,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faTableCells,
  faList,
  faUpload,
  faCamera,
  faRotateLeft,
  faHeart,
  faAddressCard,
  faCircleArrowDown,
  faCirclePause,
  faUser,
  faQuestion,
  faCircleInfo,
  faImage,
  faCartShopping,
  faArrowRight,
  faArrowLeft,
  faBars,
  faChartColumn,
  faArrowsRotate,
  faCoins,
  faBan,
  faToggleOn,
  faFloppyDisk,
  faGlobe,
  faNewspaper,
  faCalendarDays,
  faDownload,
  faRotate,
  faClockRotateLeft,
  faSliders,
  faFile,
  faListCheck,
  faExclamationTriangle,
  faStar,
  faVolumeHigh,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faLanguage,
  faUsersLine,
  faSquarePlus,
  faCircleLeft,
  faCircleMinus,
  faPenToSquare,
  faRightFromBracket,
  faMagnifyingGlass,
  faCaretUp,
  faCaretDown,
  faTrashAlt,
  faEye,
  faEyeSlash,
  faUserMinus,
  faUserPlus,
  faShare,
  faLock,
  faLockOpen,
  faUsersGear,
  faBriefcase,
  faGamepad,
  faUsers,
  faPaste,
  faCircleRight,
  faCheck,
  faFlagCheckered,
  faEnvelope,
  faPersonChalkboard,
  faPlay,
  faStop,
  faGear,
  faXmark,
  faChild,
  faCirclePlay,
  faCircleStop,
  faCircleCheck,
  faComments,
  faPaperPlane,
  faFilePen,
  faPlus,
  faFolderPlus,
  faFolder,
  faFileArrowUp,
  faFilePdf,
  faExpand,
  faCompress,
  faArrowUpWideShort,
  faArrowDownShortWide,
  faTableCells,
  faList,
  faUpload,
  faCamera,
  faRotateLeft,
  faHeart,
  faAddressCard,
  faCircleArrowDown,
  faCirclePause,
  faUser,
  faQuestion,
  faCircleInfo,
  faImage,
  faCartShopping,
  faArrowRight,
  faArrowLeft,
  faBars,
  faChartColumn,
  faArrowsRotate,
  faCoins,
  faBan,
  faToggleOn,
  faFloppyDisk,
  faGlobe,
  faNewspaper,
  faCalendarDays,
  faDownload,
  faRotate,
  faClockRotateLeft,
  faFolderPlus,
  faFilePdf,
  faSliders,
  faFile,
  faListCheck,
  faExclamationTriangle,
  faHeart,
  faStar,
  faCaretUp,
  faCaretDown,
  faVolumeHigh
)

export {FontAwesomeIcon}
