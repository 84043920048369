<template>
  <div class="jenphone-chat">
    <h2 class="jenphone-chat__title">{{ $localize('MessagesTitle') }}</h2>
    <span v-show="messages.length" @click="clear" class="jenphone-chat__clear">
      <fa-icon icon="trash-alt"></fa-icon>
      {{ $localize('MessagesClear') }}
    </span>

    <ul ref="list" class="jenphone-chat__list" v-if="messages.length">
      <StudentJenphoneMailItem v-for="m in limitedItems" :key="m.id" :message="m" />
    </ul>
    <span class="jenphone-chat__empty" v-else>{{ $localize('MessagesEmpty') }}</span>
  </div>
</template>

<script setup>
import { computed } from '@vue/runtime-core'
import { inject, ref } from 'vue';
import { useStore } from 'vuex'
import StudentJenphoneMailItem from './StudentJenphoneMailItem.vue';
import useListLimit from '@/app/base/composables/useListLimit';

const store = useStore()
const messages = computed(() => store.state.messages.messages)
const confirm = inject('confirm')
const list = ref(null)

const clear = async () => {
  const ok = await confirm.value.show({messageKey: 'StudentJenphoneClearWarn'})

  if (ok) {
    await store.dispatch('messages/clearMessages')
  }
}

const { limitedItems } = useListLimit({list: list, items: messages, step: 15})
</script>
