import { addItem, deleteItem } from '@/helpers/arrActions'
import { HTTP } from '@/axios.config'
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    currentHomework: []
  },
  mutations: {
    SET_HOMEWORK: (state, homework) => state.currentHomework = homework,
    UPDATE_HOMEWORK: (state, group) => addItem(group, state.groups),
    DELETE_GROUP: (state, group) => deleteItem(group, state.groups)
  },
  actions: {
    fetchHomework: async (ctx, group_id) => {
      const homework = await HTTP.get(`/homework`, { params: { group_id } }).then(res => res.data)
      ctx.commit('SET_HOMEWORK', homework)
    },
    createHomework: async (ctx, data) => {
      const resData = await HTTP.post('/homework/create', data).then(res => res.data)

      if (resData.status) {
        ctx.commit('UPDATE_HOMEWORK', resData.model)
        notify({
          title: store.state.locale === 'ru-RU' ? "Задание добавлено" : 'Task has been created',
          type: 'success'
        });
      } else {
        notify({
          title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
          type: 'error'
        });
      }
      return resData.status
    }
  }
}