<template>
  <li @mouseenter="read(message)" class="message" :class="{'unread': !message.is_read}">
    <div class="message__left">
      <img v-if="avatar" :src="avatar" alt="avatar">
      <img v-else src="/img/profile-icon.png" alt="avatar">
    </div>

    <div class="message__right">
      <h3 class="message__author">{{ name }}</h3>
      <p class="message__text">{{ text }}</p>
      <span class="message__date"> {{ message.created_at }} </span>
    </div>
  </li>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  message: Object
})

const store = useStore()
const locale = computed(() => store.state.locale)

const author = computed(() => {
  let a
  switch(props.message.type_id) {
    case 1:
      a = props.message.author
      break
    case 2:
      a = props.message.character
      break
    case 3:
      a = {
        name: 'Genius School',
        avatar: '/img/cipfrus.svg'
      }
      break
  }
  return a
})

const avatar = computed(() => author.value?.avatar || null)
const name = computed(() => author.value?.name[locale.value] || author.value?.name || null)
const text = computed(() => props.message.template?.text[locale.value] || 'error')

const read = (message) => {
  if (!message.is_read) store.dispatch('messages/readMessageItem', message.id)
}
</script>

<style lang="scss" scoped>
.message {
  width: 100%;
  background-color: #f2f2f2;
  padding: 0.7rem;
  border-radius: 0.4rem;
  display: flex;
  margin-top: 1rem;

  &.unread {
    box-shadow: 0 0 0 2px inset var(--a-color-1);
  }
}

.message__left {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
  margin-right: 0.6rem;
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.message__right {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.message__author {
  font-size: 1.2rem
}

.message__text {
  font-size: 1rem;
  margin-top: 5px;
}

.message__date{
  font-size: 0.8rem;
  margin-top: 1rem;
  opacity: 0.5;
  align-self: flex-end;
}
</style>
