export default [
  {
    path: '/online/:id/connect4',
    name: 'TrainerConnect4View',
    props: true,
    meta: {layout: 'empty', userRole: [2,3]},
    component: () => import('@/app/trainers/views/TrainerConnect4View'),
    children: [
      {
        path: 'start',
        name: 'Connect4Start',
        component: () => import('@/app/trainers/components/multiplayer/Connect4Start'),
      },
      {
        path: 'room',
        name: 'Connect4Room',
        component: () => import('@/app/trainers/components/multiplayer/Connect4Room'),
      },
      {
        path: 'game',
        name: 'Connect4Game',
        component: () => import('@/app/trainers/components/multiplayer/Connect4Game'),
      },
      {
        path: 'room/game',
        name: 'Connect4GameMP',
        component: () => import('@/app/trainers/components/multiplayer/Connect4GameMP'),
      }
    ]
  },
]
