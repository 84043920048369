<template>
  <div class="jenphone-rating">
    <h2>{{ $localize('StudentJenphoneRatingTitle') }}</h2>

    <StudentRatingTrainers class="jenphone-rating__content" :rating="rating" :userId="user.id" />
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { fetchTrainersRating } from '@/api/ratings'
import { useStore } from 'vuex';
import StudentRatingTrainers from './StudentRatingTrainers.vue';

const store = useStore()
const user = computed(() => store.state.user)
const age = computed(() => user.value?.property?.age || null)

const rating = ref(null)

const getRating = async() => {
  const res = await fetchTrainersRating()

  // ищем группы меньшие или равны возрасту ребенка и берем последний элемент
  const ages = Object.keys(res).filter(key => +age.value >= +key)
  const currentAgeGroup = ages[ages.length - 1]

  rating.value = res[currentAgeGroup] || []
}

await getRating()

</script>

<style lang="scss" scoped>
.jenphone-rating {
  background: var(--s-color-3);
  flex-grow: 1;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  & h2 {
    font-size: 1.4rem;
    color: var(--s-color-7);
  }
}

.jenphone-rating__content {
  margin-top: 1rem;
  flex-grow: 1;
  height: 1px;
}

</style>
