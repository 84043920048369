import { computed, ref, watch } from 'vue'

export default function useSocketsLikes(store, guide) {
  const userId = computed(() => store.state.user.id)
  const channelName = computed(() => `users.${userId.value}.likes`)
  const likesChannel = ref(null)
  const likes = computed(() => store.state.student.likes)
  const isMath = computed(() => store.state.student.subject?.id === 1)

  likesChannel.value = window.Echo
    .private(channelName.value)
    .listen('.accrual', e => {
      store.commit('student/COUNT_STUDENT_LIKES', e.count)
    })

  watch(likes, val => {
    if (val >= 10) {
      guide.value.showGuide({
        key: 'HelpMessageOnlineGamesAvaiable',
        targetClass: '.student-home__menu-item--online',
        position: 'top',
        saveGuide: true,
      })
    }

    if (val >= 5 && isMath.value) {
      guide.value.showGuide({
        key: 'HelpMessageShopAvaiable',
        targetClass: '.nav__item--shop',
        position: 'bottom',
        saveGuide: true,
      })
    }
  })

  return {
    likesChannel
  }
}
