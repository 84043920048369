import { computed, ref } from 'vue'

export default function useStudentNews(store) {
  const subjectId = computed(() => store.state.student.subject.id)
  const channelName = computed(() => `subjects.${subjectId.value}.news`)
  const newsChannel = ref(null)

  store.dispatch('news/fetchNews')

  newsChannel.value = window.Echo
    .channel(channelName.value)
    .listen('.create', e => {
      store.commit('news/ADD_NEWS_ITEM', e)
    })

  return {
    newsChannel
  }
}
