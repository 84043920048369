import { HTTP } from '@/axios.config'
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";

const fetchTeacherById = async (id) => {
  const fields = ['id', 'name', 'phone', 'email'].join(',')
  return await HTTP.get(`/teachers/${id}`, { params: { fields } }).then(res => res.data)
}

const fetchTeachers = async () => {
  const fields = ['id', 'name', 'email', 'is_active'].join(',');
  return await HTTP.get(`/teachers`, { params: { fields }}).then(res => res.data)
}

const createTeacher = async (data) => {
  const resData = await HTTP.post('/teachers/create', data).then(res => res.data)

  if (resData.status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Учитель успешно создан" : 'Teacher has been created',
      type: 'success'
    });
  } else {
    notify({
      title: resData.message ?? 'Что-то пошло не так',
      type: 'error'
    });
  }
  return resData.status
}

const updateTeacher = async (data) => {
  const resData = await HTTP.patch('/teachers/update', data).then(res => res.data)

  if (resData.status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Учитель успешно обновлен" : 'Teacher has been updated',
      type: 'success'
    });
  } else {
    notify({
      title: resData.message ?? 'Что-то пошло не так',
      type: 'error'
    });
  }
  return resData.status
}

const deleteTeacher = async (id) => {
  const status = await HTTP.delete(`/users/${id}`).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Учитель удален" : 'Teacher has been deleted',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}



export {
  createTeacher,
  updateTeacher,
  fetchTeacherById,
  fetchTeachers,
  deleteTeacher
}
