import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";

const fetchGroups = async (teacher_id) => {
  return await HTTP.get(`/teachers/${teacher_id}/groups`).then(res => res.data)
}

const fetchGroupDiaries = async (groupId) => {
  return await HTTP.get(`/groups/${groupId}/diaries`).then(res => res.data.data)
}

const fetchGroupMessage = async (id) => {
  return await HTTP.get(`/groups/${id}/message`).then(res => res.data)
}

const updateGroupMessage = async ({id, text}) => {
  const res = await HTTP.post(`/groups/${id}/message`, {text}).then(res => res.data)

  if (!res.status) {
    notify({
      title: res.message[store.state.locale] ?? store.state.locale === 'ru-RU' ? "Что-то пошло не так" : 'Error',
      type: 'error'
    })
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Сообщение сохранено" : 'Message has been updated',
      type: 'success'
    })
  }

  return res.status
}

const fetchGroupById = async ({teacherId, groupId}) => {
  return await HTTP_NO_SPINNER.get(`/teachers/${teacherId}/groups/${groupId}`).then(res => res.data)
}

const createGroup = async (data) => {
  try {
    const res = await HTTP.post('/groups', data).then(res => res.data)

    if (!res.status) return null

    notify({
      title: store.state.locale === 'ru-RU' ? "Группа успешно создана" : 'Group has been created',
      type: 'success'
    })
    return res.result

  }
  catch(e) { return null }
}

const updateGroup = async ({id, data}) => {
  const status = await HTTP.patch(`/groups/${id}`, data).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Группа обновлена" : 'Group has been updated',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}

const deleteGroup = async (id) => {
  const status = await HTTP.delete(`/groups/${id}`).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Группа удалена" : 'Group has been deleted',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}

const changeTeacher = async (id, teacher_id) => {
  const status = await HTTP.patch(`/admin/groups/${id}/change-teacher`, { teacher_id }).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Группа перенесена" : 'Group has been moved',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}

const searchGroups = async (filter) => {
  const fields = ['id', 'title', 'subject_id', 'teacher_id', 'deleted_at']
  return await HTTP.get(`/groups`, { params: { fields, ...filter } }).then(res => res.data)
}

export {
  fetchGroups,
  fetchGroupById,
  fetchGroupMessage,
  updateGroupMessage,
  createGroup,
  updateGroup,
  deleteGroup,
  searchGroups,
  changeTeacher,
  fetchGroupDiaries
}
