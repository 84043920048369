<template>
    <div class="rating" v-if="trainers.length">
      <ul class="rating__trainers scroll">
        <li
          @click="currentTrainer = t"
          :class="{ active: currentTrainer?.id === t.id }"
          v-for="t in trainers"
          :key="t.id"
          :title="t.title[locale]"
        >
          <img :src="t.icon" alt="" v-if="t.icon">
          <img src="../../../assets/img/logo-4.svg" alt="" v-else>
          <h3 v-show="showTrainerName">{{ t.title[locale] }}</h3>
        </li>
      </ul>

      <div class="rating__students">
        <h3>{{ currentTrainer?.title[locale] }}</h3>
        <ul ref="list" class="rating__list scroll">
          <StudentRatingItem
            v-for="r, i in limitedItems"
            :key="r.student_id"
            :item="r"
            :index="i + 1"
            :isCurrentStudent="+r.student_id === +studentId"
            :getScore="getScore"
          />
          <StudentRatingItem v-if="currentStat" :index="currentStat.index" :item="currentStat.item" isCurrentStudent :getScore="getScore" />
        </ul>
      </div>
    </div>

    <div class="rating" v-else>
      <p class="rating__empty">{{ $localize('StudentRatingEmpty') }}</p>
    </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import StudentRatingItem from './StudentRatingItem.vue';
import useListLimit from '@/app/base/composables/useListLimit';
const props = defineProps({
  rating: Object,
  studentId: [String, Number],
  showTrainerName: Boolean,
})

const store = useStore()
const locale = computed(() => store.state.locale)

const selected = computed(() => {
  if (!currentTrainer.value) return []

  return props.rating[currentTrainer.value.id]
})

const currentStat = computed(() => {
  const obj = selected.value.find(r => r.student_id === props.studentId)

  if (!obj || selected.value.length < 50) return null

  return {
    index: selected.value.indexOf(obj) + 1,
    item: obj
  }
})

const currentTrainer = ref(null)

const isOnline = computed(() => currentTrainer.value.type_id === 2)

const list = ref(null)
const { limitedItems } = useListLimit({list: list, items: selected, step: 50})

const getScore = (item) => {
  if (isOnline.value) return item.wins

  return item.score
}

const trainers = computed(() => {
  return Object.keys(props.rating).map(k => {
    return store.getters['trainerById'](k) || null
  }).filter(t => t)
})

watch(trainers, val => {
  if (val.length) currentTrainer.value = val[0]
})

</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';

.scroll {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f2f2f2;
    border-radius: 0.5rem;
  }
}

.rating {
  display: flex;
  height: 100%;
  // background-color: var(--s-color-3);
  overflow: hidden;

  @include adaptive(sm) {
    flex-direction: column;
  }
}
.rating__trainers {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  overflow: auto;
  background-color: rgba(0,0,0,0.1);

  @include adaptive(sm) {
    flex-direction: row;
  }

  & li {
    display: flex;
    align-items: center;
    padding: 0.5rem;

    &:hover {
      background-color: rgba(0,0,0,0.1);
    }

    & h3 {
      font-size: 0.9rem;
      color: #fff;
      margin-left: 1rem;
    }

    filter: saturate(0.2);
    cursor: pointer;

    &.active {
      filter: saturate(1);
    }

    & img {
      width: 3rem;
      height: 3rem;
      object-fit: contain;
      object-position: center;
    }
  }
}

.rating__students {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  margin-left: 1rem;

  @include adaptive(sm) {
    margin-left: 0;
    margin-top: 1rem;
  }

  & h3 {
    font-size: 1.2rem;
    color: #fff;
  }
}
.rating__list {
  width: 100%;
  flex-grow: 1;
  height: 1px;
  overflow-y: auto;
  margin-top: 1rem;
}

.rating__empty {
  margin: auto;
  font-size: 1rem;
  color: #fff;
}
</style>
