import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import store from '@/store'
import {notify} from "@kyvg/vue3-notification";

const fetchStudentsByEmail = async (search) => {
  const fields = ['id', 'email', 'phone', 'name'].join(',')
  return await HTTP.get(`/students`, { params: { search, fields } }).then(res => res.data)
}

const fetchStudentGroupLink = async ({studentId, subjectId}) => {
  const params = { subject_id: subjectId }
  const res = await HTTP_NO_SPINNER.get(`/students/${studentId}/group/link`, { params }).then(res => res.data).catch(() => null)

  if (!res || !res.status) {
    // notify({
    //   title: res.message ? res.message[store.state.locale] : 'Group link error',
    //   type: 'error'
    // })
    return null
  }

  const link = res.conference_link.split(' ').find(item => item.includes('http'))
  const arr = link.split('/')
  const identifier = arr[arr.length - 1]
  return {
    url: link ?? null,
    id: identifier,
    date: res.date + " " + res.time
  }
}

const fetchStudentGroupMessage = async ({ studentId, subjectId }) => {
  const params = { subject_id: subjectId }
  return await HTTP.get(`/students/${studentId}/group/message`, { params }).then(res => res.data).catch(() => null)
}

const fetchStudentsDistribution = async (subject_id) => {
  const fields = ['id', 'name'].join(',')
  return await HTTP.get(`/students`, { params: { subject_id, fields } }).then(res => res.data)
}

const fetchTeacherStudents = async (id) => {
  return await HTTP_NO_SPINNER.get(`/teachers/${id}/students`).then(res => res.data)
}

const fetchStudentTeachers = async (id) => {
  return await HTTP_NO_SPINNER.get(`/students/${id}/teachers`).then(res => res.data)
}

const fetchSpeedtestStudents = async (teacher_id) => {
  return await HTTP.get(`/students/speed-test`, { params: { teacher_id } }).then(res => res.data)
}

const fetchStudentById = async (id) => {
  return await HTTP.get(`/students/${id}`).then(res => res.data)
}

const fetchStudentProfile = async ({studentId, teacherId}) => {
  return await HTTP.get(`/teacher/students/${studentId}`, {params: {teacher_id : teacherId}}).then(res => res.data)
}

const fetchAdminStudents = async (search) => {
  const fields = ['id', 'name', 'email', 'is_active'].join(',')
  return await HTTP.get(`/students`, { params: { search, fields } }).then(res => res.data)
}

const fetchAttachedStudents = async ({teacherId, subjectId}) => {
  const params = {
    teacher_id: teacherId,
    subject_id: subjectId
  }
  return await HTTP.get(`/group/students`, { params }).then(res => res.data)
}

const fetchGroupStudents = async ({group_id, homework}) => {
  const withHomework = homework ? 1 : 0
  const fields = ['id', 'name'].join(',')
  return await HTTP_NO_SPINNER.get(`/groups/${group_id}/students`, {params: {need_homework: withHomework, fields}}).then(res => res.data)
}

const attachStudentToTeacher = async (data) => {
  const status = await HTTP.patch(`/teacher/students/distribution`, data).then(res => res.data)

  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Ученик прикреплен" : 'Student has been attached',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Ошибка" : 'Error',
      type: 'error'
    });
  }
}

const attachStudentToGroup = async ({ groupId, studentId }) => {
  const status = await HTTP.put(`/students/${studentId}/groups/${groupId}`).then(res => res.status)
  if (status === 201) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Успешно!" : 'Success!',
      type: 'success'
    })
  }

  return status
}

const detachStudentFromGroup = async ({ groupId, studentId }) => {
  const status = await HTTP.delete(`/students/${studentId}/groups/${groupId}`).then(res => res.data.status)

  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Успешно!" : 'Success!',
      type: 'success'
    })
  }
  return status
}

const deleteStudent = async (id) => {
  const status = await HTTP.delete(`/users/${id}`).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Ученик удален" : 'Student has been deleted',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}

const moveStudent = async (data) => {
  const status = await HTTP.post(`/students/move`, data).then(res => res.data.status)
  if (status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Ученик перенесен" : 'Student has been moved',
      type: 'success'
    });
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Произошла ошибка" : 'Error',
      type: 'error'
    });
  }
  return status
}

const switchStudentActivity = async (id) => {
  const resData = await HTTP.patch(`/students/${id}/switch-activity`).then(res => res.data)
  if (resData.status) {
    notify({
      title: resData.message,
      type: 'success'
    });
  } else {
    notify({
      title: resData.message,
      type: 'error'
    });
  }
  return resData.status
}

// Статистика студента

const fetchStudentLikes = async ({studentId, subjectId}) => {
  const res = await HTTP_NO_SPINNER.get(`students/${studentId}/likes/count`, { params: { subjectId } })
    .then(res => res.data)

  return res.result
}

const fetchStudentLikesStat = async ({student_id, subject_id, dates}) => {
  return await HTTP.get(`students/${student_id}/likes`, {params: { subject_id, dates }}).then(res => res.data)
}

const fetchStudentStat = async ({studentId, subjectId, onlyHomework, dates}) => {
  return await HTTP.get(`/students/${studentId}/statistics`, { params: { onlyHomework, dates, subjectId  } }).then(res => res.data)
}

const saveStudentStat = async (data) => {
  await HTTP.post(`/students/${data.studentId}/statistics`, { game_id: data.gameId })
}

const updateStudentStat = async (data, loader) => {
  const http = loader ? HTTP : HTTP_NO_SPINNER
  await http.patch(`/students/${data.student_id}/statistics`, data)
}

const selectCharacter = async (user_id, subject_id, character_id) => {
  await HTTP.put(`/students/${user_id}/characters/${character_id}`, { subject_id })
}

const fetchStudentCharacter = async ({studentId, subjectId}) => {
  const params = { subject_id: subjectId }
  const res = await HTTP.get(`/students/${studentId}/character`, { params }).then(res => res.data)

  return res.status ? res.result : null
}

const getStudentGroup = async({studentId, subjectId}) => {
  const params = { subject_id: subjectId }
  const res =  await HTTP.get(`/students/${studentId}/group`, { params }).then(res => res.data).catch(() => null)

  if (res) {
    res.students.sort((a, b) => b.likes_balance - a.likes_balance)
    return res
  } else {
    return null
  }
}

const getStudentGroupOnline = async({studentId, subjectId}) => {
  const params = { subject_id: subjectId }
  const res =  await HTTP_NO_SPINNER.get(`/students/${studentId}/group`, { params }).then(res => res.data).catch(() => null)

  return res?.students || []
}

const applyForPass = async (userId) => {
  const res =  await HTTP.post(`/students/${userId}/group/pass`).then(res => res.data)

  if (res.status) {
    notify({
      title: res.message[store.state.locale] ?? 'error',
      type: 'success'
    });
  } else {
    notify({
      title: res.message[store.state.locale] ?? 'error',
      type: 'error'
    });
  }
}

const attachStudentSubject = async ({ studentId, subjectId, params }) => {
  return await HTTP.put(`/students/${studentId}/subjects/${subjectId}`, params).then(res => res.data)
}

const detachStudentSubject = async ({ studentId, subjectId }) => {
  return await HTTP.delete(`/students/${studentId}/subjects/${subjectId}`).then(res => res.data)
}

const fetchStatCount = async ({ studentId, date, typeId }) => {
  return await HTTP_NO_SPINNER.get(`/students/${studentId}/statistics/count`, {params: {date, typeId}}).then(res => res.data.count)
}

export {
  // Получение учеников
  fetchStudentsByEmail,
  fetchStudentsDistribution,
  fetchAttachedStudents,
  fetchGroupStudents,
  fetchAdminStudents,
  fetchTeacherStudents,
  fetchStudentTeachers,
  fetchSpeedtestStudents,

  fetchStudentById,

  // Действия
  attachStudentToTeacher,
  attachStudentToGroup,
  detachStudentFromGroup,
  applyForPass,
  deleteStudent,
  moveStudent,
  switchStudentActivity,

  // Статистика
  fetchStudentStat,
  saveStudentStat,
  updateStudentStat,
  fetchStudentLikes,
  fetchStudentLikesStat,
  fetchStatCount,

  // Персонаж
  selectCharacter,
  fetchStudentCharacter,

  // Другое
  fetchStudentGroupMessage,
  fetchStudentGroupLink,
  fetchStudentProfile,
  getStudentGroup,
  getStudentGroupOnline,

  // Прикрепление к предмету
  attachStudentSubject,
  detachStudentSubject
}
