<template>
  <button :style="style">
    <slot></slot>
  </button>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  textColor: {
    type: String,
    default: '#000000'
  },
  bgColor: {
    type: String,
    default: '#ffffff'
  }
})

const style = computed(() => {
  return `color: ${props.textColor}; background-color: ${props.bgColor};`
})
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';
@import '/src/assets/scss/variables/variables.scss';

button {
  padding: 0.6rem 1.2rem;
  background-color: #fff;
  border-radius: 0.6rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: filter 0.3s;
  border: none;

  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    filter: saturate(0);
    opacity: 0.6;
  }
}
</style>
