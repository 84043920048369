const addItem = (el, arr) => {
  let same = arr.find(item => +item.id === +el.id)

  if (same) {
    let index = arr.indexOf(same)

    arr.splice(index, 1, el)
  } else {
    arr.unshift(el)
  }
}

const deleteItem = (el, arr) => {
  let match = arr.find(item => +item.id === +el.id)

  if (!match) return

  let index = arr.indexOf(match)
  arr.splice(index, 1)
}

const shuffleArray = (arr) => {
  let j, temp;
  for (let i = arr.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    temp = arr[j];
    arr[j] = arr[i];
    arr[i] = temp;
  }
  return arr;
}

const getElementsSum = (arr) => arr.reduce((acc, num) => acc + num, 0);


export { addItem, deleteItem, shuffleArray, getElementsSum }
