import localizeText from '@/locales/localize'
import store from '@/store'
import { notify } from '@kyvg/vue3-notification'

const checkStudentSubject = async (to, from, next) => {
  const subject = store.state.student.subject
  const status = store.state.student.status

  if (!subject) {
    next({name: 'StudentSubjectSelectView'})
  } else {
    const isAvaiable = to.meta.subjects.includes(subject.main_id)

    if (!status) {
      await store.dispatch('student/getSubjectData', subject)
    }

    if (!isAvaiable) {
      notify({
        title: localizeText('RouterErrorSubject'),
        type: 'error'
      })
      next({ name: 'StudentView' })
    } else {
      next()
    }
  }
}

export default [
  {
    path: '/student/subject',
    name: 'StudentSubjectSelectView',
    meta: {layout: 'empty', userRole: [3]},
    component: () => import('../../app/student/views/StudentSubjectSelectView'),
  },

  // MATH

  {
    path: '/student/job/select',
    name: 'StudentJobSelectView',
    meta: {layout: 'empty', userRole: [3], subjects: [1]},
    component: () => import('../../app/student/views/StudentJobSelectView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/job',
    name: 'StudentJobView',
    meta: {layout: 'student', userRole: [3], subjects: [1]},
    component: () => import('../../app/student/views/StudentJobView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/location',
    name: 'StudentLocationView',
    meta: {layout: 'empty', userRole: [3], subjects: [1]},
    component: () => import('../../app/student/views/StudentLocationView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/class/:id/location',
    name: 'StudentClassLocationView',
    props: true,
    component: () => import('../../app/student/views/StudentClassLocationView'),
    meta: {layout: 'empty', userRole: [3], subjects: [1]},
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/characters',
    name: 'StudentCharacterSelectView',
    props: true,
    meta: {layout: 'empty', userRole: [3], subjects: [1]},
    component: () => import('../../app/student/views/StudentCharacterSelectView'),
    beforeEnter: checkStudentSubject
  },

  // Mental & Reading

  {
    path: '/student/mental-test/:testId',
    name: 'StudentMentalTestView',
    props: true,
    component: () => import('../../app/student/views/StudentMentalTestView'),
    meta: {layout: 'student', userRole: [3], subjects: [1, 3]},
    beforeEnter: checkStudentSubject
  },

  {
    path: '/student/homework',
    name: 'StudentHomeworkView',
    component: () => import('../../app/student/views/StudentHomeworkView'),
    meta: {layout: 'student', userRole: [3], subjects: [2, 3, 9]},
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/albums',
    name: 'StudentAlbumsView',
    props: true,
    component: () => import('../../app/student/views/StudentAlbumsView'),
    redirect: { name: 'StudentAlbumsAllView' },
    meta: {layout: 'empty', userRole: [3], subjects: [2, 3]},
    beforeEnter: checkStudentSubject,
    children: [
      {
        path: 'all',
        name: 'StudentAlbumsAllView',
        props: true,
        component: () => import('../../app/student/views/StudentAlbumsAllView'),
        redirect: { name: 'StudentAlbumsListView' },
        children: [
          {
            path: '',
            name: 'StudentAlbumsListView',
            props: true,
            component: () => import('../../app/student/views/StudentAlbumsListView'),
          },
          {
            path: ':id',
            name: 'StudentAlbumsItemView',
            props: true,
            component: () => import('../../app/student/views/StudentAlbumsItemView')
          }
        ]
      },
      {
        path: 'crystals',
        name: 'StudentAlbumsCrystalsView',
        props: true,
        component: () => import('../../app/student/views/StudentAlbumsCrystalsView'),
      },
      {
        path: 'trading',
        name: 'StudentAlbumsTradingView',
        props: true,
        component: () => import('../../app/student/views/StudentAlbumsTradingView'),
        redirect: { name: 'StudentAlbumsTradingShopView' },
        children: [
          {
            path: 'shop',
            name: 'StudentAlbumsTradingShopView',
            props: true,
            component: () => import('../../app/student/views/StudentAlbumsTradingShopView'),
          },
          {
            path: 'market',
            name: 'StudentAlbumsTradingMarketView',
            props: true,
            component: () => import('../../app/student/views/StudentAlbumsTradingMarketView'),
          },
        ]
      }
    ]
  },

  // IT

  {
    path: '/student/courses',
    name: 'StudentItCoursesView',
    component: () => import('../../app/student/views/StudentItCoursesListView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/courses/:id',
    name: 'StudentItCoursesMapView',
    component: () => import('../../app/student/views/StudentItCoursesMapView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
    props: true
  },
  {
    path: '/student/courses/:id/block/:blockId',
    name: 'StudentItCoursesBlockView',
    component: () => import('../../app/student/views/StudentItCoursesBlockView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
    props: true
  },
  {
    path: '/student/courses/:id/block/:blockId/homework/:lessonId',
    name: 'StudentItCoursesBlockLessonView',
    component: () => import('../../app/student/views/StudentItCoursesBlockLessonView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
    props: true
  },
  {
    path: '/student/projects',
    name: 'StudentItProjectsView',
    component: () => import('../../app/student/views/StudentItProjectsView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/projects/:id',
    name: 'StudentItProjectsItemView',
    component: () => import('../../app/student/views/StudentItProjectsItemView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    props: true,
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/quizes',
    name: 'StudentItQuizesView',
    component: () => import('../../app/student/views/StudentItQuizesView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/quizes/:id',
    name: 'StudentItQuizesItemView',
    props: true,
    component: () => import('../../app/student/views/StudentItQuizesItemView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/shop',
    name: 'StudentItShopView',
    props: true,
    component: () => import('../../app/student/views/StudentItShopView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },
  {
    path: '/student/shop/buys',
    name: 'StudentItShopBuysItemView',
    props: true,
    component: () => import('../../app/student/views/StudentItShopView'),
    meta: {layout: 'it', userRole: [3], subjects: [8]},
    beforeEnter: checkStudentSubject,
  },

  // General

  {
    path: '/student',
    name: 'StudentView',
    meta: {layout: 'student', userRole: [3], subjects: [1, 2, 3, 8, 9]},
    component: () => import('../../app/student/views/StudentView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/online/',
    name: 'TrainerOnlineGamesView',
    props: true,
    meta: {layout: 'student', userRole: [3], subjects: [1, 2, 3, 8, 9]},
    component: () => import('../../app/student/views/StudentOnlineGamesView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/profile',
    meta: {layout: 'student', userRole: [3], subjects: [1, 2, 3, 8, 9]},
    component: () => import('../../app/student/views/StudentProfileView'),
    beforeEnter: checkStudentSubject,
    children: [
      {
        path: '',
        name: 'StudentProfileView',
        component: () => import('../../app/student/views/StudentProfileMenuView'),
      },
      {
        path: 'trainers',
        name: 'StudentProfileTrainersView',
        component: () => import('../../app/student/views/StudentProfileTrainersView'),
      },
      {
        path: 'homework',
        name: 'StudentProfileHwView',
        component: () => import('../../app/student/views/StudentProfileHwView'),
      },
      {
        path: 'likes',
        name: 'StudentProfileLikesView',
        component: () => import('../../app/student/views/StudentProfileLikesView'),
      },
      {
        path: 'rating/online',
        name: 'StudentProfileRatingOnlineView',
        component: () => import('../../app/student/views/StudentProfileRatingOnlineView'),
      },
      {
        path: 'rating/trainers',
        name: 'StudentProfileRatingTrainersView',
        component: () => import('../../app/student/views/StudentProfileRatingTrainersView'),
      },
      {
        path: 'certificates',
        name: 'StudentProfileCertificatesView',
        component: () => import('../../app/student/views/StudentProfileCertificatesView'),
      },
      {
        path: 'diary',
        name: 'StudentProfileDiaryView',
        component: () => import('../../app/student/views/StudentProfileDiaryView'),
      },
      {
        path: 'rating/likes',
        name: 'StudentProfileRatingLikesView',
        component: () => import('../../app/student/views/StudentProfileRatingLikesView'),
      }
    ]
  },
  {
    path: '/student/trainers',
    name: 'StudentTrainersView',
    meta: {layout: 'student', userRole: [3], subjects: [1, 2, 3, 9]},
    component: () => import('../../app/student/views/StudentTrainersView'),
    beforeEnter: checkStudentSubject
  },
  {
    path: '/student/class',
    name: 'StudentClassView',
    component: () => import('../../app/student/views/StudentClassView'),
    meta: {layout: 'student', userRole: [3], subjects: [1, 2, 3, 8, 9]},
    redirect: {name: 'StudentClassItem'},
    beforeEnter: checkStudentSubject,
    children: [
      {
        path: 'schedule',
        name: 'StudentClassItem',
        component: () => import('../../app/student/components/StudentClassItem'),
      },
      {
        path: 'materials',
        name: 'StudentClassMaterials',
        component: () => import('../../app/student/components/StudentClassMaterials'),
      },
    ]
  }
]
