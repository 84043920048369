import { fetchStudentNews, updateStudentNewsItem } from '@/api/news'

export default {
  namespaced: true,
  state: {
    news: [],
  },
  getters: {
    lastNewsItem: (state, getters, rootState) => {
      const locale = rootState.locale
      const data = state.news.filter(n => n.lang === locale && n.is_required === 0)

      return data[data.length - 1]
    },
    lastRequiredNewsItem: (state, getters, rootState) => {
      const locale = rootState.locale
      const data = state.news.filter(n => n.lang === locale && n.is_required === 1 && n.is_read === 0)

      return data[data.length - 1]
    },
    localeNews: (state, getters, rootState) => {
      const locale = rootState.locale
      return state.news.filter(n => n.lang === locale)
    }
  },
  mutations: {
    SET_NEWS: (state, news) => {
      state.news = news
    },
    SET_LAST_NEWS: (state, item) => {
      state.lastNewsItem = item
    },
    READ_NEWS_ITEM: (state, id) => {
      let news = state.news.find(m => m.id === id)

      if (news) news.is_read = true
    },
    ADD_NEWS_ITEM: (state, item) => {
      state.news.unshift(item)
    }
  },
  actions: {
    fetchNews: async ({ commit, rootState }) => {
      const studentId = rootState.user.id
      const subjectMainId = rootState.student.subject.main_id
      const news = await fetchStudentNews({ studentId, subjectMainId })
      commit('SET_NEWS', news)
    },
    readNewsItem: async ({ commit, rootState }, newsId) => {
      const userId = rootState.user.id

      await updateStudentNewsItem({
        studentId: userId,
        newsId: newsId,
        params: { is_read: true }
      })

      commit('READ_NEWS_ITEM', newsId)
    }
  }
}
