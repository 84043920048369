<template>
  <div class="jenphone" :class="{ showed: isShowed }">
    <button class="jenphone__close" @click="close"></button>
    <div class="jenphone__content">
      <div class="jenphone__header">
        <span>{{ time }}</span>
        <span>{{ weekDay }}</span>
      </div>

      <div class="jenphone__body">
        <component ref="jenElement" :is="currentComponent"></component>
      </div>

      <div class="jenphone__footer">
        <ul class="jenphone__menu">
          <li
            class="jenphone__menu-item jenphone__menu-item--chat"
            @click="currentComponent = chatComponent"
            :class="{active: currentComponent === chatComponent}"
          >
            <span class="visually-hidden">Чат</span>
            <span v-show="hasUnreadMessages" class="unread"></span>
            <fa-icon icon="comments"></fa-icon>
          </li>

          <li
            class="jenphone__menu-item jenphone__menu-item--message"
            @click="currentComponent = mailComponent"
            :class="{active: currentComponent === mailComponent}"
            v-show="!isTeacher"
          >
            <span class="visually-hidden">Почта</span>
            <span v-show="unread" class="unread">+{{ unread }}</span>
            <fa-icon icon="envelope"></fa-icon>
          </li>

          <li
            class="jenphone__menu-item jenphone__menu-item--news"
            @click="currentComponent = newsComponent"
            :class="{active: currentComponent === newsComponent}"
            v-show="!isTeacher"
          >
            <span class="visually-hidden">Новости</span>
            <fa-icon icon="newspaper"></fa-icon>
          </li>

          <li
            class="jenphone__menu-item jenphone__menu-item--rating"
            @click="currentComponent = ratingComponent"
            :class="{active: currentComponent === ratingComponent}"
            v-show="!isTeacher"
          >
            <span class="visually-hidden">Рейтинг</span>
            <fa-icon icon="calendar-days"></fa-icon>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import StudentJenphoneMail from '@/app/student/components/StudentJenphoneMail.vue'
import StudentJenphoneChat from '@/app/student/components/StudentJenphoneChat.vue'
import StudentJenphoneNews from '@/app/student/components/StudentJenphoneNews.vue'
import StudentJenphoneRating from '@/app/student/components/StudentJenphoneRating.vue'

import { computed, markRaw, onBeforeUnmount, onMounted, ref } from 'vue'
import moment from 'moment'
import { useStore } from 'vuex'

defineProps({
  isShowed: Boolean,
  isTeacher: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])

const mailComponent = markRaw(StudentJenphoneMail)
const chatComponent = markRaw(StudentJenphoneChat)
const newsComponent = markRaw(StudentJenphoneNews)
const ratingComponent = markRaw(StudentJenphoneRating)

const store = useStore()
const locale = computed(() => store.state.locale)
const userId = computed(() => store.state.user.id)
const unread = computed(() => store.getters['messages/unreadMessages'])

const jenElement = ref(null)
const hasUnreadMessages = computed(() => jenElement.value?.hasUnreadMessages)
defineExpose({ hasUnreadMessages })

const currentComponent = ref(chatComponent)
const time = ref(null)
const weekDay = moment().locale(locale.value).format('dddd, DD MMMM')

const startClock = () => {
  const now = new Date()
  time.value = now.toLocaleTimeString()
}

const close = () => {
  emit('close')
  currentComponent.value = chatComponent
}

const clockInterval = ref(null)

window.Echo
  .private(`chat.user.${userId.value}`)
  .listen('.chat', (e) => store.commit('messages/ADD_MESSAGE', e))

onMounted(() => {
  clockInterval.value = setInterval(startClock, 1000)
})
onBeforeUnmount(() => {
  window.Echo.leave(`chat.user.${userId.value}`)
  clearInterval(clockInterval.value)
})
</script>
