import { computed, onBeforeUnmount, onMounted, ref } from 'vue'

export default function useListLimit({ list, items, step = 50 }) {
  const limit = ref(step)

  const limitedItems = computed(() => items.value.slice(0, limit.value))

  const increase = () => {
    if (limit.value < items.value.length) {
      limit.value += step
    }
  }

  const onScroll = (e) => {
    e.preventDefault()
    const total = list.value.scrollHeight - 1
    const scroll = list.value.scrollTop + list.value.clientHeight

    if (scroll >= total) increase()
  }

  onMounted(() => list.value.addEventListener('scroll', onScroll))
  onBeforeUnmount(() => list.value.removeEventListener('scroll', onScroll))

  return {
    limitedItems
  }
}
