import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {polyfill} from "mobile-drag-drop";
import {scrollBehaviourDragImageTranslateOverride} from "mobile-drag-drop/scroll-behaviour";

polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride
});

// Packages components

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Notifications from '@kyvg/vue3-notification'
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import MathJax, { initMathJax, renderByMathjax } from 'mathjax-vue3'
import CKEditor from '@ckeditor/ckeditor5-vue';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';
import '@ckeditor/ckeditor5-build-classic/build/translations/uk';

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import VueYandexMetrika from 'vue3-yandex-metrika'
import Loader from 'vue-spinner/src/BeatLoader.vue'

// drag n drop for mobile

import 'drag-drop-touch'

function onMathJaxReady() {
  const el = document.getElementById('mathjax')
  renderByMathjax(el)
}

initMathJax({}, onMathJaxReady)

// Sockets

import Echo from 'laravel-echo';

const host = window.location.hostname;
const port = process.env.NODE_ENV === 'production' ? null : 6001;

window.Pusher = require('pusher-js');
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'app-key',
  wsHost: process.env.NODE_ENV === 'production' ? host : 'localhost',
  wsPort: port,
  wssPort: port,
  forceTLS: process.env.NODE_ENV === 'production',
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  auth: {
    headers: {
      Authorization: 'Bearer ' + store.state.auth.jwt || null
    }
  }
})

// Icons

import { FontAwesomeIcon } from '@/library/icons'

// Locale

import localizeText from './locales/localize'

// Styles

import './assets/scss/main.scss'

// Directives

import {vCutValue} from '@/directives/vCutValue'
import {vTooltip} from '@/directives/vTooltip'
const app = createApp(App)

app.component('fa-icon', FontAwesomeIcon)
app.component('DatePicker', Datepicker);
app.component('VPagination', VPagination)
app.component('QuillEditor', QuillEditor)
app.component('AppLoader', Loader);

app.config.globalProperties.$localize = localizeText

app.directive('cut', vCutValue)
app.directive('tooltip', vTooltip)

app.use(VueYandexMetrika, {
  id: 92338829,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

app.use(Notifications)
app.use(CKEditor)
app.use(Vue3Lottie)
app.use(MathJax)
app.use(store)
app.use(router)
app.mount('#app')
