import { fetchSubjects } from '@/api/subjects'
import { deleteTeacher, fetchTeachers } from '@/api/teachers'
import { createUser, updateUser } from '@/api/users'
import { addItem, deleteItem } from '@/helpers/arrActions'
export default {
  namespaced: true,
  state: {
    subjects: [],
    teachers: []
  },
  getters: {
    subjectById: (state) => (id) => state.subjects.find(s => +s.id === +id),
    teacherById: (state) => (id) => state.teachers.find(s => +s.id === +id)
  },
  mutations: {
    UPDATE_SUBJECTS: (state, subjects) => state.subjects = subjects,
    UPDATE_TEACHERS: (state, teachers) => state.teachers = teachers,
    REMOVE_TEACHER: (state, teacher) => deleteItem(teacher, state.teachers),
    ADD_TEACHER: (state, teacher) => addItem(teacher, state.teachers)
  },
  actions: {
    getSubjects: async (ctx) => {
      const subjects = await fetchSubjects()

      if (subjects) {
        ctx.commit('UPDATE_SUBJECTS', subjects)
      }
    },
    getTeachers: async (ctx) => {
      const teachers = await fetchTeachers()

      if (teachers) {
        ctx.commit('UPDATE_TEACHERS', teachers)
      }
    },
    createTeacher: async ({ commit }, data) => {
      const res = await createUser(data)

      if (res) {
        commit('ADD_TEACHER', res)
      }
    },
    updateTeacher: async ({ commit }, { id, user }) => {
      const res = await updateUser({ id, user })

      if (res) {
        commit('ADD_TEACHER', res)
      }
    },
    removeTeacher: async ({ commit }, teacher) => {
      const res = await deleteTeacher(teacher.id)

      if (res) {
        commit('REMOVE_TEACHER', teacher)
      }
    },
  }
}
