import {
  fetchStudentAblumPictures,
  fetchStudentAblumPuzzles,
  fetchStudentAblums,
  saveAlbumPicture,
  updatePuzzles,
} from '@/api/albums'
import localizeText from '@/locales/localize';
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    albums: [],
    pictures: [],

    puzzles: [],
    selectedPuzzles: [],
    dropped: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
    dragged: null
  },
  getters: {
    isSelectedPuzzle: state => id => state.selectedPuzzles.includes(id),
    puzzleCounts: state => picId => {
      const pic = state.pictures.find(p => p.id === picId)
      let counts = []

      pic.puzzles.map(i => i.id).forEach(p => {
        const count = state.puzzles.reduce((acc, el) => {
          if (el.id === p) acc++
          return acc;
        }, 0)
        counts.push(count)
      })
      return counts
    },
    inPicture: state => state.dropped.some(p => p.puzzle_student_id === state.dragged),

    isFull: state => state.dropped.every(item => item.id),
    isCorrect: state => {
      let correct = true

      for (let i = 1; i < state.dropped.length; i++) {
        const prev = state.dropped[i - 1]
        const current = state.dropped[i]

        const isExist = prev.id && current.id
        const isSamePictureId = prev.picture_id === current.picture_id
        const correctOrder = prev.id < current.id

        const isSameLegendary = prev.is_legendary === current.is_legendary
        const currentPicture = state.pictures.find(p => p.id === prev.picture_id)
        const isLegendaryPicture = currentPicture?.is_legendary

        if (!isExist || !isSamePictureId || !correctOrder) {
          correct = false
        }

        if (isLegendaryPicture && !isSameLegendary) {
          correct = false
        }
      }

      return correct
    }
  },
  actions: {
    getStudentAlbums: async ({ commit, rootState }) => {
      const studentId = rootState.user.id
      const albums = await fetchStudentAblums(studentId)

      commit('SET_ALBUMS', albums)
    },
    getStudentPictures: async ({ commit, rootState }, albumId) => {
      const studentId = rootState.user.id
      const params = {
        relations: ['title', 'puzzles'],
        appends: ['image', 'puzzles.image']
      }
      const pictures = await fetchStudentAblumPictures({ studentId, albumId, params })

      commit('SET_PICTURES', pictures)
    },
    getStudentPuzzles: async ({ commit, rootState }, albumId) => {
      const studentId = rootState.user.id
      const params = {
        appends: ['image'],
        sort: {
          field: 'id',
          type: 'random'
        }
      }
      const puzzles = await fetchStudentAblumPuzzles({ studentId, albumId, params })

      commit('SET_PUZZLES', puzzles)
    },
    savePicture: async ({ rootState, dispatch, commit }, data) => {
      const studentId = rootState.user.id
      const { pictureId, isCrystalAction, params } = data

      params.subject_id = rootState.student.subject.id

      const status = await saveAlbumPicture({ studentId, pictureId, params })

      if (status) {
        commit('UPDATE_PICTURE', { pictureId, params, isCrystalAction })
        commit('CLEAR_DROPPED')
        dispatch('getStudentAlbums')
      }
    },
    makeLegendaryPuzzles: async ({ commit, state, rootState, dispatch }) => {
      const ids = state.selectedPuzzles

      if (!ids.length) {
        notify({
          title: localizeText('AlbumsStoreNotify2'),
          type: 'error'
        })
        return
      }

      const studentId = rootState.user.id
      const status = await updatePuzzles({
        studentId,
        params: {
          puzzle_student_ids: ids,
          is_legendary: true
        }
      })

      if (status) {
        commit('MAKE_LEGENDARY', ids)
        dispatch('crystals/nullCrystal', {
          crystalId: 1,  // legendary id
          params: { charge: 0 }
        }, {root: true})
      }

      state.selectedPuzzles = []
    }
  },
  mutations: {
    SET_ALBUMS: (state, albums) => state.albums = albums,
    SET_PICTURES: (state, pictures) => state.pictures = pictures,
    SET_PUZZLES: (state, puzzles) => state.puzzles = puzzles,

    SET_DRAGGED: (state, id) => state.dragged = id,
    DROP_TO_ITEMS: (state) => {
      const el = state.dropped.find(p => p.puzzle_student_id === state.dragged)

      if (!el) return

      const elIndex = state.dropped.indexOf(el)

      state.puzzles.push(el)
      state.dropped.splice(elIndex, 1, {})
    },
    DROP_TO_FIELD: (state, fieldIndex) => {
      const userPuzzleEl = state.puzzles.find((p) => p.puzzle_student_id === state.dragged)
      const isTargetEmpty = !state.dropped[fieldIndex].id

      if (userPuzzleEl && isTargetEmpty) {
        const picture = state.pictures.find(p => p.id === userPuzzleEl.picture_id)

        if (picture.is_legendary && !userPuzzleEl.is_legendary) {
          notify({
            title: localizeText('AlbumsStoreNotify3'),
            type: 'warn'
          })
          return
        }
        state.puzzles = state.puzzles.filter((p) => p.puzzle_student_id !== state.dragged)
        state.dropped.splice(fieldIndex, 1, userPuzzleEl)
      }
      if (!userPuzzleEl && state.dragged) {
        const puzzle = state.dropped.find((p) => p.puzzle_student_id === state.dragged)
        const index = state.dropped.indexOf(puzzle)
        state.dropped[fieldIndex] = state.dropped.splice(index,1,state.dropped[fieldIndex])[0]
      }

      state.dragged = null
    },
    UPDATE_PICTURE: (state, data) => {
      const picture = state.pictures.find(p => p.id === data.pictureId)
      const keys = Object.keys(data.params)

      if (picture) {
        keys.forEach(k => {
          picture[k] = data.params[k]
        })
      }
      // если активирован кристалл-проявитель
      if (data.isCrystalAction) {
        const crystal = state.crystals.find(c => c.id === 3)
        crystal.charge = 0
        state.activated = []
      }
    },
    SELECT_PUZZLE: (state, puzzle) => {
      const exist = state.selectedPuzzles.find(p => p === puzzle.puzzle_student_id)

      if (puzzle.is_legendary) {
        notify({
          title: localizeText('AlbumsStoreNotify4'),
          type: 'warn'
        })
        return
      }

      if (exist) {
        const index = state.selectedPuzzles.indexOf(exist)
        state.selectedPuzzles.splice(index, 1)
        return
      }

      const MAX = 5

      if (state.selectedPuzzles.length < MAX) {
        state.selectedPuzzles.push(puzzle.puzzle_student_id)
      } else {
        notify({
          title: localizeText('AlbumsStoreNotify5'),
          type: 'warn'
        })
      }
    },
    MAKE_LEGENDARY: (state, ids) => {
      ids.forEach(id => {
        const puzzle = state.puzzles.find(p => p.puzzle_student_id === id )
        puzzle.is_legendary = true
      })
    },
    CLEAR_PUZZLES: (state) => {
      const arr = state.dropped.filter((p) => p.id)

      arr.forEach((el) => state.puzzles.unshift(el))
      state.dropped = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
    },
    CLEAR_DRAGGED: state => state.dragged = null,
    CLEAR_SELECTED_PUZZLES: (state) => state.selectedPuzzles = [],
    CLEAR_DROPPED: state => state.dropped = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
  }
}
