import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import authPaths from '@/assets/other/authPaths'
import { notify } from "@kyvg/vue3-notification";

// modules
import admin from './routes/admin'
import teacher from './routes/teacher'
import student from './routes/student'
import picnic from './routes/picnic'
import connect4 from './routes/connect4'
import shop from './routes/shop'
import localizeText from '@/locales/localize';

const checkLogin = async (loginPage = false) => {
  const { status, user } = store.state.auth

  if (status) {
    const role = user.role_id
    router.push({name: authPaths[role]})
    return
  }

  if (!loginPage) router.push({name: 'AuthLoginView'})
}

const checkTrainerAvaiable = async (route) => {
  let user = store.state.user
  let subjId = store.state.student.subject?.id || null

  if (user.role_id === 3) {
    const trainer = store.getters.trainerById(+route.params.id)

    if (!trainer || !trainer.subjects.some(t => t.id === subjId)) {
      router.push({name: 'StudentTrainersView'})
    }
  }
}

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  },
  {
    path: '/404',
    meta: {layout: 'trainer'},
    component: () => import('@/app/base/views/PageNotFound')
  },
  {
    path: '/',
    meta: {layout: 'empty'},
    name: 'home',
    beforeEnter: () => checkLogin(false)
  },
  {
    path: '/login',
    meta: {layout: 'empty'},
    component: () => import('@/app/base/views/AuthLoginView'),
    beforeEnter: () => checkLogin(true),
    children: [
      {
        path: '',
        name: 'AuthLoginView',
        component: () => import('@/app/base/components/AppAuthLoginForm'),
      },
      {
        path: 'restore',
        name: 'AuthLoginRestorePass',
        component: () => import('@/app/base/components/AppAuthLoginRestorePass'),
      }
    ]
  },
  {
    path: '/registration',
    meta: {layout: 'empty'},
    name: 'AuthRegistrationView',
    component: () => import('@/app/base/views/AuthRegistationView')
  },
  {
    path: '/trainers/:id',
    name: 'TrainerGameView',
    props: true,
    meta: {layout: 'trainer', userRole: [2, 3]},
    component: () => import('@/app/trainers/views/TrainerGameView'),
    beforeEnter: checkTrainerAvaiable
  },
  {
    path: '/trainers/homework/:id',
    name: 'TrainerHomeworkView',
    props: true,
    meta: {layout: 'trainer', userRole: [3]},
    component: () => import('@/app/trainers/views/TrainerHomeworkView')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    ...routes,
    ...admin,
    ...student,
    ...teacher,
    ...connect4,
    ...picnic,
    ...shop
  ]
})

router.afterEach(() => {
  store.state.loading = false
})

router.beforeEach(async (to, from, next) => {
  store.state.loading = true

  const { status, user } = store.state.auth

  const requireAuth = to.matched.some(record => record.meta.userRole)

  if (!requireAuth) {
    next()
  } else if (!status) {
    notify({
      title: localizeText('RouterErrorLogin'),
      type: 'error'
    })
    next({name: 'AuthLoginView'})
  } else {
    store.commit('SET_USER', user)
    const isAvaiable = to.meta.userRole.includes(user.role_id)

    if (!isAvaiable) {
      notify({
        title: localizeText('RouterErrorRole'),
        type: 'error'
      })
      next({ name: authPaths[user.role_id] })
    } else {
      next()
    }
  }

  store.state.loading = false
})

export default router
