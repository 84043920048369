<template>
  <label :style="`color: ${color};`" class="label" :class="{ checked: isChecked, disabled }">    <slot></slot>
    <input
      class="checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      :disabled="disabled"
      @change="updateInput"
    />
  </label>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  value: [Number, String, Object],
  color: { type: String, default: '#000' },
  modelValue: { default: '' },
  trueValue: { default: true },
  falseValue: { default: false },
  disabled: Boolean
})

const emit = defineEmits(['update:modelValue'])

const isChecked = computed(() => {
  if (props.modelValue instanceof Array) {
    if (typeof props.value == 'object') {
      return props.modelValue.some(v => v.id == props.value.id)
    }
    return props.modelValue.includes(props.value)
  }
  return props.modelValue === props.trueValue
})

const updateInput = (event) => {
  let isChecked = event.target.checked
  if (props.modelValue instanceof Array) {
    let newValue = [...props.modelValue]
    if (isChecked) {
      newValue.push(props.value)
    } else {
      newValue.splice(newValue.indexOf(props.value), 1)
    }
    emit('update:modelValue', newValue)
  } else {
    emit('update:modelValue', isChecked ? props.trueValue : props.falseValue)
  }
}


</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';
@import '/src/assets/scss/variables/variables.scss';

label {
  position: relative;
  font-size: 1rem;
  margin: 0.4rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: -1px;
    // transform: translateY(-50%);
    width: 1.4rem;
    height: 1.4rem;
    background: linear-gradient(
      135.17deg,
      #cbcbcb 8.09%,
      #8c8c8c 30.77%,
      #8c8c8c 30.78%,
      #4a4a4a 54.35%
    );
    border: 0.6px solid #000000;
    box-shadow: inset 1px -1px 1px rgba(0, 0, 0, 0.58),
      inset -1px 1px 1px rgba(0, 0, 0, 0.43);
    border-radius: 0.2rem;
  }
  & input {
    display: none;
  }

  &.disabled {
    opacity: 0.7;
  }

  &.checked::after {
    position: absolute;
    content: '';
    top: 0.2rem;
    left: 0.3rem;
    width: 0.8rem;
    height: 0.8rem;
    background: $bg-color-4;
    border: 0.6px solid #000000;
    box-shadow: inset 2px 2px 2px rgba(255, 255, 255, 0.61),
    inset -2px -1px 2px rgba(0, 0, 0, 0.44);
    border-radius: 0.2rem;
  }
}
</style>
