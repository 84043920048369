import {
  fetchStudentCrystals,
  useCrystal,
  buyCrystal
} from '@/api/albums'
import localizeText from '@/locales/localize';
import { notify } from "@kyvg/vue3-notification";

export default {
  namespaced: true,
  state: {
    crystals: [],
    activated: [],
  },
  getters: {
    crystal: (state) => (id) => state.crystals.find(c => c.id === id) || null,
    isActivated: state => id => state.activated.includes(id),
    isCombo: state => id => {
      const combos = [[2, 4]]

      return combos.some(combo => combo.every(c => state.activated.includes(c)) && combo.includes(id))
    }
  },
  actions: {
    getStudentCrystals: async ({ commit, rootState }) => {
      const studentId = rootState.user.id
      const params = {
        fields: ['id', 'price', 'is_for_sale'],
        relations: ['title', 'description '],
        appends: ['image']
      }
      const crystals = await fetchStudentCrystals(studentId, params)

      // crystals.forEach(c => c.charge = 7)

      commit('SET_CRYSTALS', crystals)
    },
    buyCrystalItem: async ({commit, rootState}, crystal) => {
      const crystalId = crystal.id
      const studentId = rootState.user.id
      const subjectId = rootState.student.subject.id

      const status = await buyCrystal({studentId, subjectId, crystalId})

      if (status) {
        commit('ADD_CRYSTAL', crystalId)
      }
    },
    nullCrystal: async ({commit, dispatch, rootState, state}, {crystalId, params}) => {
      const studentId = rootState.user.id
      params.subject_id = rootState.student.subject.id
      const status = await useCrystal({studentId, crystalId, params})

      state.activated = []
      if (status) {
        commit('USE_CRYSTAL', crystalId)

        if (crystalId === 5) {
          dispatch('getStudentCrystals')
        }
      }
    }
  },
  mutations: {
    SET_CRYSTALS: (state, crystals) => state.crystals = crystals,
    ADD_CRYSTAL: (state, id) => {
      const target = state.crystals.find(c => c.id === id)
      target.count++

      // если получен ПЕРВЫЙ кристалл, добавляем поле charge
      if (target.count === 1) target.charge = 0
    },

    ACTIVATE_CRYSTAL: (state, id) => {
      const crystal = state.crystals.find(c => c.id === id)
      const max = 2

      if (!crystal) return

      if (crystal.charge < 7) {
        notify({
          title: localizeText('CrystalsStoreNotify1'),
          type: 'error'
        })
        return
      }

      if (state.activated.length === max) {
        state.activated = []
        return
      }

      if (!state.activated.includes(id)) {
        state.activated.push(id)
      } else {
        state.activated = state.activated.filter(item => item !== id)
      }
    },
    USE_CRYSTAL: (state, id) => {
      const crystal = state.crystals.find(c => c.id === id)
      crystal.charge = 0
    }
  }
}
