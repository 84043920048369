import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'

const fetchMarketLots = async (params) => {
  return await HTTP_NO_SPINNER.get(`/market/lots`, {params}).then(res => res.data.data)
}

const fetchRating = async () => {
  return await HTTP_NO_SPINNER.get(`/crystals/students`).then(res => res.data)
}

const createMarketLot = async (data) => {
  return await HTTP.post(`/market/lots`, data).then(res => res.data.status)
}

const buyMarketLot = async ({ id, params }) => {
  return await HTTP.patch(`/market/lots/${id}`, params).then(res => res.data.status)
}

const deleteMarketLot = async ({ studentId, lotId }) => {
  return await HTTP.delete(`/market/students/${studentId}/lots/${lotId}`).then(res => res.data.status)
}

export {
  fetchMarketLots,
  fetchRating,
  createMarketLot,
  buyMarketLot,
  deleteMarketLot
}
