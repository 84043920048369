<template>
  <Suspense>
    <div class="empty-layout">
      <router-view></router-view>
    </div>
  </Suspense>
</template>

<style lang="scss" scoped>
.empty-layout {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
</style>
