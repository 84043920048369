import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";

const saveSpeedtestResult = async (data) => {
  const res = await HTTP.post('/trainers/speed-test', data).then(res => res.data)

  if (res.status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Результат записан" : 'Result has been saved',
      type: 'success'
    });
  } else {
    notify({
      title: res.validate_message,
      type: 'error'
    });
  }
  return res.validate_status
}

const getWords = async (length, words_count, lang, operator) => {
  return  await HTTP.get(`/trainers/words`, {
    params: {
      length,
      words_count,
      operator,
      lang
    }
  }).then(res => res.data);
}

const fetchMemoCards = async ({lang, subject_id}) => {
  const res = await HTTP.get(`/trainers/memo`, {params: {lang, subject_id}}).then(res => res.data)

  if (!res.status) return []
  else return res.result
}

const fetchGymVideos = async () => {
  return await HTTP.get(`/trainers/gymnastics/videos`).then(res => res.data)
}

const fetchAlgebraTask = async () => {
  return await HTTP.get(`/trainers/algebra`).then(res => res.data)
}

const fetchTrainers = async ({userId, subjectId}) => {
  return await HTTP_NO_SPINNER.get('/trainers', {params: {user_id: userId, subject_id: subjectId}}).then(res => res.data)
}

const saveMentalStat = async (data) => {
  return await HTTP_NO_SPINNER.post('/mental-stats', data).then(res => res.data)
}

const fetchSpeechData = async (data) => {
  const { mode, letters, syllableTypes, places } = data

  const res = await HTTP.get('/speech-images', { params: { mode, letters, syllableTypes, places } }).then(res => res.data)
  const modeId = data.mode

  let obj = {}

  if ([1,2].includes(modeId)) {
    for(const types of Object.values(res)) {
      Object.values(types).forEach(v => {
        obj = { ...obj, ...v }
      })
    }
  } else {
    Object.values(res).forEach(v => {
      obj = { ...obj, ...v }
    })
  }
  return Object.keys(obj).map(k => {
    return {
      image: obj[k].image || null,
      text: obj[k].text || null,
      sound: obj[k].audio || null
    }
  })
}

const checkSpeechSize = async({ size, data, error }) => {
  const imgs = await fetchSpeechData(data)

  if (size > imgs.length) {
    notify({
      title: error ?? `Недостаточно карточек(${imgs.length}) для заданного поля`,
      type: 'error'
    })
    return false
  }

  return true
}

export {
  saveSpeedtestResult,
  getWords,
  fetchMemoCards,
  fetchAlgebraTask,
  fetchTrainers,
  saveMentalStat,
  fetchGymVideos,
  fetchSpeechData,
  checkSpeechSize
}
