<template>
  <Suspense>
    <div class="student-layout">
      <router-view @openJenphone="showJenphone = !showJenphone" v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>

      <Jenphone :isShowed="showJenphone" @close="showJenphone = false" />
      <GameInvite v-if="isOnlineGameAvaiable" />
      <StudentLastNewsItem />
    </div>
  </Suspense>
</template>

<script setup>
import Jenphone from '@/app/student/components/StudentJenphone.vue'
import { computed, defineAsyncComponent, inject, markRaw, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import GameInvite from '@/app/trainers/components/TrainerGameInvite.vue'
import useStudentNews from '@/app/student/composables/useStudentNews'
import useSubjectTheme from '@/app/student/composables/useSubjectTheme'

import StudentLastNewsItem from '@/app/student/components/StudentLastNewsItem.vue'

const showJenphone = ref(false)
const store = useStore()
const isOnlineGameAvaiable = computed(() => store.getters['student/isOnlineGamesAvaiable'])

const modal = inject('modal')
const NewsItemPopup = markRaw(defineAsyncComponent(() => import('@/app/admin/components/AdminNewsItemPopup.vue')))

const requiredNews = computed(() => store.getters['news/lastRequiredNewsItem'])
useStudentNews(store)

onMounted(() => useSubjectTheme(store))

watch(requiredNews, val => {
  if (val && val.is_read === 0) {
    modal.value.open({
      component: NewsItemPopup,
      data: { id: requiredNews.value.id },
      params: {
        outsideClose: false
      }
    })
  }
})
</script>

<style lang="scss" scoped>
.student-layout {
  background-image: var(--s-bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100%;
}
</style>
@/app/student/composables/useStudentNews@/app/student/composables/useSubjectTheme
