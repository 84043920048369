<template>
  <div class="invite fade" v-if="invite">
    <h3>{{ invite.message[locale] }}</h3>
    <CustomButton @click="$router.push({ path: invite.link, query: { getQueue: true } })">
      {{ $localize('TrainerInviteGamePlay') }}
    </CustomButton>

    <AppCheckbox color="var(--s-color-7)" v-model="available">{{ $localize("TrainerInviteGameDisable") }}</AppCheckbox>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, ref } from 'vue'
import { useStore } from 'vuex'
import useSounds from '@/app/base/composables/useSounds'
import CustomButton from '@/app/base/components/AppButtonSecond.vue'
import { useStorage } from '@vueuse/core'
import AppCheckbox from '@/app/base/components/AppCheckbox.vue'

const store = useStore()
const locale = computed(() => store.state.locale)
const invite = ref(null)
const { playSound } = useSounds()
const available = useStorage('gameInviteAvailable', true)

const showInvite = (e) => {
  const { message, trainer } = e
  invite.value = {
    message,
    link: `/online/${trainer.id}/${trainer.component.toLowerCase()}/start`
  }

  setTimeout(() => invite.value = null, 10000)
}
const connectChannel = () => {
  window.Echo
    .channel(`invite.users`)
    .listen('.online.invite', (e) => {
      if (available.value) {
        showInvite(e)
        playSound('invite')
      }
    })
}

connectChannel()
onBeforeUnmount(() => window.Echo.leave('invite.users'))
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';
@import '/src/assets/scss/variables/variables.scss';

.invite {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
  padding: 15px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  & h3 {
    text-align: center;
    @include fz(18, 14, 16, 17);
    color: $font-color-2;
  }

  @include adaptive(sm) {
    max-width: 100%;
  }
}
</style>
