<template>
  <div class="loading-layout">
    <div class="loading-layout__img scale">

    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-layout {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-image: url('../assets/img/bg-main-blur.webp');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.loading-layout__img {
  width: 100px;
  height: 100px;
  background-image: url('../assets/img/logo-3.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
