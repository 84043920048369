<template>
  <section class="it-layout">
    <RouterView />
  </section>
</template>

<script setup></script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';

.it-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: #7292F2;
}
</style>
