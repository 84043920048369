import { fetchProjects } from '@/api/itProjects'

export default {
  namespaced: true,
  state: {
    projects: [],
    top: [],
    filter: {
      title: '',
      categoryId: null,
      orderBy: 'id'
    },
    page: 1,
    perPage: 20,
    loading: false,
    pages: 1
  },
  getters: {

  },
  mutations: {
    SET_PROJECTS: (state, projects) => {
      state.projects = projects
    },
    SET_TOP: (state, top) => {
      state.top = top
    },
    SET_FILTER: (state, filter) => {
      Object.keys(filter).forEach(k => state.filter[k] = filter[k])
    },
    SET_PAGE: (state, page) => {
      state.page = page
    },
    SET_LIKE: (state, { id, isLiked }) => {
      const last = state.projects.find(p => p.id === id)
      const top = state.top.find(p => p.id === id)

      if (top?.is_liked !== isLiked) top.is_liked = isLiked
      if (last?.is_liked !== isLiked) last.is_liked = isLiked
    },
    SET_PAGES: (state, pages) => {
      state.pages = pages
    }
  },
  actions: {
    getProjects: async ({ commit, state }) => {
      state.loading = true
      const res = await fetchProjects({
        ...state.filter,
        perPage: state.perPage,
        page: state.page
      })
      state.loading = false
      commit('SET_PROJECTS', res.data)
      commit('SET_PAGES', res.last_page)
    },
    next: ({state, commit, dispatch}) => {
      commit.SET_PAGE(state.page + 1)
      dispatch.getProjects()
    },
    getTop: async ({ commit }) => {
      const res = await fetchProjects({
        title: '',
        categoryId: null,
        orderBy: 'likes_count',
        perPage: 20,
        page: 1
      })

      commit('SET_TOP', res.data)
    },
  }
}
