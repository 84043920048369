import store from '../store'
import ru from './ru.json'
import en from './en.json'
import uk from './uk.json'

const locales = {
  'ru-RU': ru,
  'en-US': en,
  'uk-UA': uk
}

export default function localizeText(key) {
  const locale = store.state.locale || 'ru-RU'
  return locales[locale][key] || key
}
