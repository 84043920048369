import { HTTP, HTTP_NO_SPINNER } from "@/axios.config";
// import { notify } from "@kyvg/vue3-notification";

const fetchNews = async () => {
  const res = await HTTP.get('/news').then(res => res.data.data)
  return res
}

const fetchStudentNews = async ({studentId, subjectMainId}) => {
  const params = { main_subject_id: subjectMainId }
  const res = await HTTP_NO_SPINNER.get(`students/${studentId}/news`, { params }).then(res => res.data.data)
  return res
}

const fetchNewById = async (id) => {
  return await HTTP.get(`/news/${id}`).then(res => res.data.data)
}

const createNews = async (data) => {
  return await HTTP.post(`/news`, data).then(res => res.data)
}

const updateNews = async ({id, data}) => {
  return await HTTP.post(`/news/${id}`, data).then(res => res.data)
}
const updateStudentNewsItem = async ({studentId, newsId, params }) => {
  await HTTP_NO_SPINNER.patch(`students/${studentId}/news/${newsId}`, params).then(res => res.data)
}
const removeNews = async (id) => {
  return await HTTP.delete(`/news/${id}`).then(res => res.data)
}

export {
  fetchNews,
  fetchStudentNews,
  fetchNewById,
  createNews,
  updateNews,
  updateStudentNewsItem,
  removeNews
}
