import { HTTP } from '@/axios.config'
import store from '@/store'
import router from '@/router'
import { notify } from "@kyvg/vue3-notification";
// import authPaths from '@/assets/other/authPaths'
import localizeText from '@/locales/localize';

const register = async (data) => {
  HTTP.post('/register', data).then(res => {
    const status = res.data.status

    if (status) {
      notify({
        title: localizeText('requestAuthRegSuccess'),
        type: 'success'
      });
      router.push({name: 'AuthLoginView'})
    } else {
      notify({
        title: localizeText('requestAuthRegError'),
        type: 'error'
      });
    }
  })
}
const authLogin = async (data) => {
  const res = await HTTP.post('/login', data).then(res => res)

  if (res.status === 200) {
    const user = res.data
    const jwtToken = res.headers['jwt-token']

    notify({
      title: localizeText('requestAuthLoginSuccess'),
      type: 'success'
    })

    return { status: true, user: user, jwt: jwtToken }
  }

  const message = res.data.message[store.state.locale] ?? localizeText('requestAuthLoginError')

  notify({
    title: message,
    type: 'error'
  })
  return { status: false, user: null, jwt: null }
}

const logout = async () => {
  const userRole = store.state.user.role_id

  if (userRole === 3) {
    window.Echo.leave(`chat.user.${store.state.user.id}`)
    store.commit('student/SET_STUDENT_SUBJECT', null)
  }

  window.Echo.leave(`online.user.${store.state.user.id}`)
  store.commit('auth/LOGOUT')

  router.push({name: 'AuthLoginView'})
}

const resetPassword = async ({email, user_id, lang}) => {
  const status = await HTTP.post('/reset', {email, user_id, lang}).then(res => res.data.status)
  if (status) {
    notify({
      title: localizeText('requestAuthPasswordResetSuccess'),
      type: 'success'
    })
  } else {
    notify({
      title: localizeText('requestAuthPasswordResetError'),
      type: 'error'
    })
  }
  return status
}

const changePassword = async ({user_id, password, code}) => {
  const res = await HTTP.post('/reset/confirm', {user_id, password, code}).then(res => res.data)
  if (res.status) {
    notify({
      title: localizeText('requestAuthPasswordChangeSuccess'),
      type: 'success'
    })
  } else {
    notify({
      title: localizeText('requestAuthPasswordChangeError'),
      type: 'error'
    })
  }
  return res.status
}

export { register, authLogin, logout, resetPassword, changePassword}
