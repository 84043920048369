<template>
  <div class="last-new" v-if="item && !item.is_read">
    <h2>{{ $localize('StudentLastNewTitle') }}</h2>
    <p v-cut="90">{{ item.title }}</p>
    <button @click="openItem">{{ $localize('StudentLastNewBtn') }} >></button>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, markRaw } from 'vue';
import { useStore } from 'vuex';

const store = useStore()
const item = computed(() => store.getters['news/lastNewsItem'])

const modal = inject('modal')

const NewsItemPopup = markRaw(defineAsyncComponent(() => import('@/app/admin/components/AdminNewsItemPopup.vue')))
const openItem = () => {
  modal.value.open({
    component: NewsItemPopup,
    data: { id: item.value.id }
  })
}
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';
@keyframes anim {
  0% {
    background-color: #5500bc;
  }
  50% {
    background-color: #6b00b3;
  }
  100% {
    background-color: #5500bc;
  }
}
.last-new {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;
  background-color: #fff;
  border-radius: 0.7rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-right: 1rem;
  z-index: 9;

  @include adaptive(sm) {
    flex-direction: column;
    padding-right: 0;
    padding-bottom: 1rem;
  }
  & h2 {
    display: block;
    font-size: 0.8rem;
    text-transform: uppercase;
    background-color: #1F42BF;
    color: #fff;
    padding: 0.8rem 1.6rem;
    animation: anim 3s infinite;

    @include adaptive(sm) {
      width: 100%;
    }
  }

  & p {
    flex-grow: 1;
    font-size: 0.8rem;
    font-weight: bold;
    padding: 0.8rem 1.6rem;
    text-align: center;
  }

  & button {
    border: none;
    background-color: transparent;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    flex-shrink: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
