import { authLogin } from '@/api/auth'

const auth = JSON.parse(localStorage.getItem('auth'));
const initialState = auth
  ? { status: true, jwt: auth.jwt, user: auth.user }
  : { status: false, jwt: null, user: null }

const configEcho = (jwt) => {
  window.Echo.connector.options.auth.headers.Authorization = 'Bearer ' + jwt
}

export default {
  namespaced: true,
  state: initialState,
  getters: {

  },
  mutations: {
    SET_AUTH_DATA: (state, data) => {
      state.status = data.status
      state.user = data.user
      state.jwt = data.jwt

      localStorage.setItem('auth', JSON.stringify(state))
      configEcho(state.jwt)
    },
    UPDATE_JWT: (state, jwt) => {
      state.jwt = jwt

      localStorage.setItem('auth', JSON.stringify(state))
      configEcho(state.jwt)
    },
    LOGOUT: (state) => {
      state.status = false
      state.user = null
      state.jwt = null

      localStorage.removeItem('auth')

      if (localStorage.subject) {
        localStorage.removeItem('subject')
      }
    }
  },
  actions: {
    login: async ({ commit }, data) => {
      const authData = await authLogin(data)

      if (!authData.status) return

      commit('SET_AUTH_DATA', authData)
      commit('SET_USER', authData.user, { root: true })
    }
  }
}
