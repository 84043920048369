<template>
  <div class="guide" ref="guide" @click.self="close" v-show="isShowed">
    <div class="guide__message">
      <button class="close-button-second" @click="close"></button>
      <p class="guide__text">{{ message }}</p>
    </div>
  </div>
</template>

<script setup>
import { updateStudentGuide } from '@/api/users'
import localizeText from '@/locales/localize'
import { computed, ref } from '@vue/runtime-core'
import { useStore } from 'vuex'

const store = useStore()
const user = computed(() => store.state.user)

const isShowed = ref(false)
const guide = ref(null)

const message = ref(null)
const targetEl = ref(null)

const close = () => {
  isShowed.value = false
  message.value = null
  if (targetEl.value) {
    targetEl.value.style.zIndex = 0
    targetEl.value = null
  }
}

const showGuide = async ({
  key,
  targetClass = null,
  position = null,
  width = 260,
  saveGuide = false,
}) => {
  if (targetEl.value) return
  if (saveGuide) {
    const existKey = user.value.property?.guides?.includes(key)
    if (existKey) return

    await updateStudentGuide(user.value.id, key)
  }

  targetEl.value = document.querySelector(targetClass)

  guide.value.style.setProperty('--width', width + 'px')
  message.value = localizeText(key)

  if (targetEl.value && position) {
    targetEl.value.style.zIndex = 1000
    const coords = targetEl.value.getBoundingClientRect()
    const widthDiff = width - coords.width
    guide.value.style.setProperty('--transform', 'none')

    switch (position) {
      case 'bottom right':
        guide.value.style.setProperty('--posY', coords.bottom + 'px')
        guide.value.style.setProperty('--posX', coords.right + 'px')
        break

      case 'bottom left':
        guide.value.style.setProperty('--posY', coords.bottom + 'px')
        guide.value.style.setProperty('--posX', coords.left - width + 'px')
        break

      case 'top left':
        guide.value.style.setProperty(
          '--posY',
          coords.top - coords.height + 'px'
        )
        guide.value.style.setProperty('--posX', coords.left - width + 'px')
        break

      case 'top right':
        guide.value.style.setProperty(
          '--posY',
          coords.top - coords.height + 'px'
        )
        guide.value.style.setProperty('--posX', coords.right + 'px')
        break

      case 'top':
        guide.value.style.setProperty(
          '--posY',
          coords.top - coords.height - 20 + 'px'
        )
        guide.value.style.setProperty(
          '--posX',
          coords.left - widthDiff / 2 + 'px'
        )
        break

      case 'bottom':
        guide.value.style.setProperty(
          '--posY',
          coords.top + coords.height + 20 + 'px'
        )
        guide.value.style.setProperty(
          '--posX',
          coords.left - widthDiff / 2 + 'px'
        )
        break

      case 'left':
        guide.value.style.setProperty('--posY', coords.top + 'px')
        guide.value.style.setProperty(
          '--posX',
          coords.left + coords.width + 20 + 'px'
        )
        break

      case 'right':
        guide.value.style.setProperty('--posY', coords.top + 'px')
        guide.value.style.setProperty('--posX', coords.left - width - 20 + 'px')
        break
    }
  }

  isShowed.value = true
}
defineExpose({ showGuide })
</script>

<style lang="scss" scoped>
.guide {
  --posX: 50%;
  --posY: 50%;
  --width: 200px;
  --height: max-content;
  --transform: translate(-50%, -50%);

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.guide__message {
  position: absolute;
  top: var(--posY);
  left: var(--posX);
  transform: var(--transform);
  background-color: #fff;
  width: var(--width);
  height: var(--height);
  border: 1px solid #000;
  border-radius: 10px;
  padding: 40px 20px;
  text-align: center;
  z-index: 9999;
}

.guide__text {
  font-size: 1rem;
}
</style>
