<template>
  <ul ref="el" v-if="isShowing" class="context" :style="styles">
    <li v-for="item in menuItems" :key="item.id" class="context__item">
      <button @click="onClick(item)" :disabled="item.disabled">
        <span class="icon">
          <fa-icon v-if="item.icon" :icon="item.icon"></fa-icon>
        </span>

        {{ $localize(item.key) }}
      </button>
    </li>
  </ul>
</template>

<script setup>
import { computed, reactive, ref } from "vue";
import { onClickOutside } from '@vueuse/core'

const isShowing = ref(false)
const menuItems = ref([])
const coords = reactive({x: 0, y: 0})

const styles = computed(() => {
  return `top: ${ coords.y }px; left: ${ coords.x }px;`
})

const open = ({x, y, items}) => {
  isShowing.value = true
  coords.x = x
  coords.y = y
  menuItems.value = [...items]
}

const close = () => {
  isShowing.value = false
  coords.x = 0
  coords.y = 0
  menuItems.value = []
}

const onClick = (item) => {
  item.onClick()
  close()
}

const el = ref(null)

onClickOutside(el, close)

defineExpose({ open })
</script>

<style lang="scss" scoped>
.context {
  position: fixed;
  top: 0;
  left: 0;
  width: 12rem;
  height: auto;
  background-color: #fff;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0;
  box-shadow: 0 0 2px 0 #a1a1a1;
  z-index: 99;
}
.context__item {
  width: 100%;

  & .icon {
    display: block;
    width: 1rem;
    margin-right: 0.2rem;
    color: #666;
  }
  & button {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0.4rem;
    padding-left: 0.8rem;
    cursor: pointer;
    font-size: 0.8rem;
    text-align: left;

    &:hover {
      background-color: #e2e2e2;
    }
  }
}
</style>
