import axios from 'axios'
import store from '@/store'
import { notify } from "@kyvg/vue3-notification";
import localizeText from './locales/localize';

const host = window.location.hostname
const url = host ? `https://${host}/api` : 'https://math.genius-school.online/api'

const HTTP = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? url : 'http://localhost:8080/api'
})

const HTTP_NO_SPINNER = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? url : 'http://localhost:8080/api',
})

HTTP.interceptors.request.use(config => {
  store.state.loading = true
  const { jwt } = store.state.auth

  if (jwt) config.headers.common.Authorization = 'Bearer ' + jwt

  return config;
});

HTTP_NO_SPINNER.interceptors.request.use(config => {
  const { jwt } = store.state.auth

  if (jwt) config.headers.common.Authorization = 'Bearer ' + jwt

  return config;
});

HTTP.interceptors.response.use(resp => {
  store.state.loading = false
  const jwt = resp.headers['jwt-token']

  if (jwt) store.commit('auth/UPDATE_JWT', jwt)

  return resp;
}, (error) => {
  console.log(error)
  const message = error.response.data.message
  const statusCode = error.response.status
  const code = error.response.data.code

  if (statusCode === 419 && message === 'CSRF token mismatch.') {
    window.location.replace('/')
  }

  if (statusCode === 503 && code === 'MAINTENANCE_MODE_ACTIVE') {
    window.location.replace('/')
  }
  store.state.loading = false

  notify({
    title: localizeText('requestCommonError'),
    text: message ? message[store.state.locale] : error,
    type: 'error'
  });

  return Promise.reject(error);
});
export { HTTP, HTTP_NO_SPINNER }
