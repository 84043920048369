<template>
  <Suspense>
    <div class="trainer-layout">
      <router-view></router-view>
    </div>
  </Suspense>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.trainer-layout {
  width: 100vw;
  height: 100vh;
  background-image: var(--s-bg-image);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  overflow: auto;
}
</style>
