<template>
  <transition name="fade">
    <div class="confirm" v-if="isOpened">
      <div class="confirm__body">
        <span class="confirm__icon"></span>
        <h2>{{ $localize('AppConfirmTitle') }}</h2>
        <p>{{ $localize(message) }}</p>

        <div class="confirm__buttons">
          <button @click="cancel">{{ $localize('AppConfirmNo') }}</button>
          <button @click="confirm">{{ $localize('AppConfirmYes') }}</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref } from 'vue'

const isOpened = ref(false)
const message = ref('')
const resolvePromise = ref(null)
const rejectPromise = ref(null)

const show = (params = {}) => {
  message.value = params.messageKey
  isOpened.value = true

  return new Promise((resolve, reject) => {
    resolvePromise.value = resolve
    rejectPromise.value = reject
  })
}
const cancel = () => {
  isOpened.value = false
  resolvePromise.value(false)
}
const confirm = () => {
  isOpened.value = false
  resolvePromise.value(true)
}

defineExpose({ show })
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';

.confirm {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.confirm__body {
  background-color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
  border-radius: 0.5rem;

  @include adaptive(sm) {
    width: 100%;
    max-width: 400px;
  }

  & h2 {
    font-size: 1.4rem;
    margin-top: 1rem;
  }

  & p {
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
  }
}
.confirm__icon {
  display: block;
  width: 4rem;
  height: 4rem;
  background-image: url('../../../assets/img/warning-icon.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.confirm__buttons {
  margin-top: 2rem;

  & button {
    background: #E2E2E2;
    border-radius: 0.5rem;
    border: none;
    font-size: 0.8rem;
    padding: 0.5rem;
    width: 7rem;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      filter: brightness(0.9)
    }

    &:first-child {
      margin-right: 1rem;
    }
    &:last-child {
      background: #BD0000;
      color: #fff;
    }
  }
}
</style>
