import { createStore } from 'vuex'

import auth from './modules/auth'
import homework from './modules/homework'
import messages from './modules/messages'
import student from './modules/student'
import teacher from './modules/teacher'
import animations from './modules/animations'
import albums from './modules/albums'
import crystals from './modules/crystals'
import market from './modules/market'
import news from './modules/news'
import admin from './modules/admin'
import projects from './modules/projects'

import { addItem } from '@/helpers/arrActions'
import { fetchTrainers } from '@/api/trainers'

import languages from '@/locales/languages'

const getLocale = () => {
  const startLocale = localStorage.getItem('locale')
  const UAHost = location.hostname === 'gelios-school'

  // для укр домена
  if (UAHost) return 'uk-UA'

  // при записи в локал стораж
  if (startLocale) return startLocale

  // иначе смотрим по языку браузера
  if (navigator.language) {
    const navLang = languages.find(l => l.includes(navigator.language))

    return navLang || 'en-US'
  }

  return 'ru-RU'
}

export default createStore({
  state: {
    loading: false,
    locale: getLocale(),
    user: null,
    trainers: []
  },
  getters: {
    trainerById: (state) => (id) => {
      let trainer = state.trainers.find(item => +item.id === +id)
      return trainer
    }
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    },
    SET_TRAINERS(state, trainers) {
      state.trainers = trainers
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_USER_AVATAR(state, image) {
      state.user.avatar = image
    },
    UPDATE_STUDENT_GUIDE(state, key) {
      if (!state.user.property?.guides) {
        state.user.property.guides = []
      }
      addItem(key, state.user.property.guides)
    }
  },
  actions: {
    async getTrainers({commit, state}, subjectId = null) {
      const userId = state.user.id
      const trainers = await fetchTrainers({ userId, subjectId })

      // trainers.push({
      //   id: 99,
      //   title: {'ru-RU': 'Лабиринт'},
      //   component: 'LabyrinthSpeech',
      //   subjects: [1, 2, 3, 4, 5, 6, 7, 8],
      //   type_id: 1
      // })

      commit('SET_TRAINERS', trainers)
    }
  },
  modules: {
    auth,
    homework,
    messages,
    animations,
    student,
    albums,
    crystals,
    market,
    news,
    teacher,
    admin,
    projects
  }
})
