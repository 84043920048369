<template>
  <div class="jenphone-news">
    <h2 class="jenphone-news__title">{{ $localize('StudentJenphoneNewsTitle') }}</h2>

    <ul class="jenphone-news__list" v-if="news.length">
      <li
        class="jenphone-news__item item"
        :class="{'unread': !n.is_read}"
        v-for="n in news"
        :key="n.id"
        @click="openItem(n.id)"
      >
        <h3>{{ n.title }}</h3>

      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from '@vue/runtime-core'
import { defineAsyncComponent, inject, markRaw } from 'vue';
import { useStore } from 'vuex'

const store = useStore()

const news = computed(() => store.getters['news/localeNews'])
const modal = inject('modal')
const NewsItemPopup = markRaw(defineAsyncComponent(() => import('@/app/admin/components/AdminNewsItemPopup.vue')))

const openItem = (id) => {
  modal.value.open({
    component: NewsItemPopup,
    data: { id }
  })
}
</script>

<style lang="scss" scoped>
.jenphone-news {
  background: #fff;
  flex-grow: 1;
  padding: 1.4rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  & h3 {
    font-size: 1.2rem;
  }
}

.jenphone-news__list {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.jenphone-news__item {
  background-color: #f2f2f2;
  padding: 1rem;
  cursor: pointer;

  & h3 {
    font-size: 1.2rem;
  }
}
</style>
