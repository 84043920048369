export default [
  {
    path: '/shop',
    name: 'ShopView',
    props: true,
    meta: {layout: 'student', userRole: [3]},
    component: () => import('../../app/student/views/StudentShopView'),
    redirect: {name: 'ShopCharacterView'},
    children: [
      {
        path: 'character',
        name: 'ShopCharacterView',
        props: true,
        component: () => import('../../app/student/views/StudentShopCharacterView'),
      },
      {
        path: 'home',
        name: 'ShopHomeView',
        props: true,
        component: () => import('../../app/student/views/StudentShopHomeView'),
      },
      {
        path: 'job',
        name: 'ShopJobView',
        props: true,
        component: () => import('../../app/student/views/StudentShopJobView'),
      }
    ]
  },
]
