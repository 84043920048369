import { HTTP, HTTP_NO_SPINNER } from '@/axios.config'
import { notify } from "@kyvg/vue3-notification";
import store from '@/store'

const fetchActivities = async () => {
  return await HTTP.get(`/field-activities`).then(res => res.data)
}

const fetchActivityById = async (id) => {
  const res = await HTTP.get(`/field-activities/${id}`).then(res => res.data)

  if (!res.status) return []
  return res.result
}

const fecthStudentActivity = async (id) => {
  const res = await HTTP.get(`/students/${id}/field-activity`).then(res => res.data)

  if (!res.status) return null
  return res.result
}

const saveStudentActivity = async ({studentId, activityId}) => {
  const res = await HTTP.post(`/students/${studentId}/field-activity`, {
    field_activity_id: activityId
  }).then(res => res.data)

  if (!res.status) {
    notify({
      title: store.state.locale === 'ru-RU' ? "Что-то пошло не так" : 'Error',
      type: 'error'
    })
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Сфера деятельности выбрана" : 'Activity field has been selected',
      type: 'success'
    })
  }

  return res.status
}

const fetchLocationThings = async ({studentId, locationId}) => {
  return await HTTP.get(`/students/${studentId}/locations/${locationId}/things/shop`).then(res => res.data)
}

const fetchUserLocationThings = async ({studentId, locationId}) => {
  return await HTTP.get(`/students/${studentId}/locations/${locationId}/things`).then(res => res.data)
}

const fetchLocationImage = async ({ studentId, type, spinner = false }) => {
  const req = spinner ? HTTP : HTTP_NO_SPINNER
  const res = await req.get(`/students/${studentId}/locations/${type}`).then(res => res.data)

  if (typeof res !== 'string') return null
  return res
}

const fetchLocationPreview = async ({ studentId, locationId, things }) => {
  const res = await HTTP_NO_SPINNER.post(`/students/${studentId}/locations/${locationId}/things/preview`, {things}).then(res => res.data)
  if (!res.status) return null

  return res.result
}

const applyStudentThing = async ({studentId, locationId, things}) => {
  return await HTTP.patch(`/students/${studentId}/locations/${locationId}`, {things}).then(res => res.data)
}

const buyThings = async ({studentId, locationId, things}) => {
  const res = await HTTP.post(`/students/${studentId}/locations/${locationId}/things`, { things: things }).then(res => res.data)
  if (!res.status) {
    notify({
      title: res.message,
      type: 'error'
    })
  } else {
    notify({
      title: store.state.locale === 'ru-RU' ? "Покупка успешно завершена" : 'Purchase completed successfully',
      type: 'success'
    })
  }
}

const createThing = async ({locationId, things}) => {
  return await HTTP.post(`/locations/${locationId}/things`, things).then(res => res.data)
}

export {
  fetchActivities,
  fetchActivityById,
  fecthStudentActivity,

  fetchLocationThings,
  fetchUserLocationThings,
  fetchLocationImage,
  fetchLocationPreview,
  saveStudentActivity,
  applyStudentThing,
  buyThings,
  createThing
}
