import { HTTP } from '@/axios.config'

const fetchSubjects = async () => {
  return await HTTP.get('/subjects').then(res => res.data)
}

const fetchStudentSubjects = async (id) => {
  return await HTTP.get(`/students/${id}/subjects`).then(res => res.data)
}

const getCharactersBySubject = async (subjectId) => {
  return await HTTP.get(`/subjects/${subjectId}/characters`).then(res => res.data)
}

export { fetchSubjects, getCharactersBySubject, fetchStudentSubjects }
