export default [
  {
    path: '/online/:id/picnic',
    name: 'TrainerPicnicView',
    props: true,
    meta: {layout: 'empty', userRole: [2, 3]},
    component: () => import('@/app/trainers/views/TrainerPicnicView'),
    children: [
      {
        path: 'start',
        name: 'PicnicStart',
        component: () => import('@/app/trainers/components/multiplayer/PicnicStart'),
      },
      {
        path: 'single',
        name: 'PicnicSingle',
        component: () => import('@/app/trainers/components/multiplayer/PicnicSingle'),
      },
      {
        path: 'game',
        name: 'PicnicGame',
        component: () => import('@/app/trainers/components/multiplayer/PicnicGame'),
      },
      {
        path: 'result',
        name: 'PicnicResult',
        component: () => import('@/app/trainers/components/multiplayer/PicnicResult'),
      },
      {
        path: 'room',
        name: 'PicnicRoom',
        component: () => import('@/app/trainers/components/multiplayer/PicnicRoom'),
      }
    ]
  },
]
