<template>
  <transition name="fade">
    <div class="modal" v-if="isOpened" @click.self="close">
      <div class="modal__body">
        <component
          :is="currentComponent"
          :data="currentData"
          @close="isOpened = false"
        ></component>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { reactive, ref } from 'vue'

const currentComponent = ref(null)
const currentData = ref(null)
const currentParams = reactive({
  outsideClose: true
})
const isOpened = ref(false)

const open = (popupParams) => {
  const { component, data, params } = popupParams

  currentData.value = data
  currentComponent.value = component
  if (params) {
    Object.keys(currentParams).forEach(k => {
      if (params[k] !== undefined) {
        currentParams[k] = params[k]
      }
    })
  }

  isOpened.value = true
}

const close = () => {
  if (currentParams.outsideClose) {
    isOpened.value = false
  }
}

defineExpose({ open })
</script>

<style lang="scss" scoped>
@import '/src/assets/scss/mixins/mixins.scss';

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__body {
  min-width: 340px;
  min-height: 300px;
  max-width: 1200px;

  @include adaptive(sm) {
    width: 100%;
  }
}
</style>
